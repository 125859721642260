<script setup>
import { inject } from "vue"
import Page from "../components/Page.vue"

const { lang } = inject("lang")

const courses = [
  {
    year: 0,
    semester: "기이수",
    department: "인문사회과학과",
    code_: 10.022,
    code: "HSS022",
    class: "",
    type: "교필",
    titleKor: "English Presentation & Discussion",
    credits: 1,
    AU: 0,
    again: "N",
    grade: "S",
    titleEng: "English Presentation & Discussion",
    professor: "",
  },
  {
    year: 0,
    semester: "기이수",
    department: "인문사회과학과",
    code_: 10.023,
    code: "HSS023",
    class: "",
    type: "교필",
    titleKor: "Advanced English Listening",
    credits: 1,
    AU: 0,
    again: "N",
    grade: "S",
    titleEng: "Advanced English Listening",
    professor: "",
  },
  {
    year: 0,
    semester: "기이수",
    department: "인문사회과학과",
    code_: 10.024,
    code: "HSS024",
    class: "",
    type: "교필",
    titleKor: "Advanced English Writing",
    credits: 1,
    AU: 0,
    again: "N",
    grade: "S",
    titleEng: "Advanced English Writing",
    professor: "",
  },
  {
    year: 0,
    semester: "기이수",
    department: "인문사회과학과",
    code_: 10.025,
    code: "HSS025",
    class: "",
    type: "교필",
    titleKor: "Advanced English Reading",
    credits: 1,
    AU: 0,
    again: "N",
    grade: "S",
    titleEng: "Advanced English Reading",
    professor: "",
  },
  {
    year: 2018,
    semester: "봄학기",
    department: "인문사회과학부",
    code_: 10.09,
    code: "HSS090",
    class: 16,
    type: "교필",
    titleKor: "즐거운 대학생활",
    credits: 0,
    AU: 1,
    again: "N",
    grade: "S",
    titleEng: "Happy College Life",
    professor: "박상희",
  },
  {
    year: 2018,
    semester: "봄학기",
    department: "인문사회과학부",
    code_: 10.1,
    code: "HSS190",
    class: "J",
    type: "자선",
    titleKor: "새내기 세미나 1<전기및전자공학부>",
    credits: 1,
    AU: 0,
    again: "N",
    grade: "S",
    titleEng: "Freshman Seminar 1<School of Electrical Engineering>",
    professor: "이융",
  },
  {
    year: 2018,
    semester: "봄학기",
    department: "인문사회과학부",
    code_: 10.174,
    code: "HSS062",
    class: "O",
    type: "교필",
    titleKor: "인성/리더십 III<새내기를 위한 합창>",
    credits: 0,
    AU: 1,
    again: "N",
    grade: "S",
    titleEng: "Humanity/Leadership III<Freshman Choral Class>",
    professor: "",
  },
  {
    year: 2018,
    semester: "봄학기",
    department: "물리학과",
    code_: 20.141,
    code: "PH141",
    class: "D",
    type: "기필",
    titleKor: "일반물리학 I",
    credits: 3,
    AU: 0,
    again: "N",
    grade: "A-",
    titleEng: "General Physics I",
    professor: "문희태",
  },
  {
    year: 2018,
    semester: "봄학기",
    department: "생명과학과",
    code_: 21.12,
    code: "BS120",
    class: "H",
    type: "기필",
    titleKor: "일반생물학",
    credits: 3,
    AU: 0,
    again: "N",
    grade: "A0",
    titleEng: "General Biology",
    professor: "한진희",
  },
  {
    year: 2018,
    semester: "봄학기",
    department: "화학과",
    code_: 23.101,
    code: "CH101",
    class: "A",
    type: "기필",
    titleKor: "일반화학 I",
    credits: 3,
    AU: 0,
    again: "N",
    grade: "B+",
    titleEng: "General Chemistry I",
    professor: "도영규",
  },
  {
    year: 2018,
    semester: "봄학기",
    department: "화학과",
    code_: 23.102,
    code: "CH102",
    class: "E",
    type: "기필",
    titleKor: "일반화학실험 I",
    credits: 1,
    AU: 0,
    again: "N",
    grade: "B+",
    titleEng: "General Chemistry Experiment I",
    professor: "윤동기",
  },
  {
    year: 2018,
    semester: "봄학기",
    department: "수리과학과",
    code_: 25.101,
    code: "MAS101",
    class: "F",
    type: "기필",
    titleKor: "미적분학 I",
    credits: 3,
    AU: 0,
    again: "N",
    grade: "B+",
    titleEng: "Calculus I",
    professor: "최건호",
  },
  {
    year: 2018,
    semester: "가을학기",
    department: "인문사회과학부",
    code_: 10.091,
    code: "HSS091",
    class: 16,
    type: "교필",
    titleKor: "신나는 대학생활",
    credits: 0,
    AU: 1,
    again: "N",
    grade: "S",
    titleEng: "Exciting College Life",
    professor: "박상희",
  },
  {
    year: 2018,
    semester: "가을학기",
    department: "인문사회과학부",
    code_: 10.11,
    code: "HSS189",
    class: "K",
    type: "자선",
    titleKor: "새내기 세미나 2<항공우주공학과>",
    credits: 1,
    AU: 0,
    again: "N",
    grade: "S",
    titleEng: "Freshman Seminar 2<Dept. of Aerospace Engineering>",
    professor: "안재명",
  },
  {
    year: 2018,
    semester: "가을학기",
    department: "물리학과",
    code_: 20.142,
    code: "PH142",
    class: "D",
    type: "기필",
    titleKor: "일반 물리학 II",
    credits: 3,
    AU: 0,
    again: "N",
    grade: "B+",
    titleEng: "General Physics II",
    professor: "장홍영",
  },
  {
    year: 2018,
    semester: "가을학기",
    department: "물리학과",
    code_: 20.151,
    code: "PH151",
    class: "E",
    type: "기필",
    titleKor: "일반물리학실험 I",
    credits: 1,
    AU: 0,
    again: "N",
    grade: "B+",
    titleEng: "General Physics Lab. I",
    professor: "이순칠",
  },
  {
    year: 2018,
    semester: "가을학기",
    department: "수리과학과",
    code_: 25.109,
    code: "MAS109",
    class: "K",
    type: "기선",
    titleKor: "선형대수학개론",
    credits: 3,
    AU: 0,
    again: "N",
    grade: "B0",
    titleEng: "Introduction to Linear Algebra",
    professor: "김동환",
  },
  {
    year: 2018,
    semester: "가을학기",
    department: "산업및시스템공학과",
    code_: 31.101,
    code: "IE101",
    class: "A",
    type: "기선",
    titleKor: "OR개론",
    credits: 3,
    AU: 0,
    again: "N",
    grade: "A0",
    titleEng: "Introduction to Operations Research",
    professor: "김세헌",
  },
  {
    year: 2018,
    semester: "가을학기",
    department: "전산학부",
    code_: 36.101,
    code: "CS101",
    class: "G",
    type: "기필",
    titleKor: "프로그래밍기초",
    credits: 3,
    AU: 0,
    again: "N",
    grade: "A+",
    titleEng: "Introduction to Programming",
    professor: "최기선",
  },
  {
    year: 2018,
    semester: "가을학기",
    department: "전산학부",
    code_: 36.204,
    code: "CS204",
    class: "B",
    type: "전필",
    titleKor: "이산구조",
    credits: 3,
    AU: 0,
    again: "N",
    grade: "A+",
    titleEng: "Discrete Mathematics",
    professor: "마틴 지글러",
  },
  {
    year: 2019,
    semester: "봄학기",
    department: "인문사회과학부",
    code_: 10.103,
    code: "HSS001",
    class: "H",
    type: "교필",
    titleKor: "논리적글쓰기",
    credits: 3,
    AU: 0,
    again: "N",
    grade: "A0",
    titleEng: "Logical Writing",
    professor: "배하은",
  },
  {
    year: 2019,
    semester: "봄학기",
    department: "인문사회과학부",
    code_: 10.173,
    code: "HSS061",
    class: "C",
    type: "교필",
    titleKor: "인성/리더십 II<그룹리더십 GLA>",
    credits: 0,
    AU: 1,
    again: "N",
    grade: "S",
    titleEng: "Humanity/Leadership II<Group Leadership Activity>",
    professor: "",
  },
  {
    year: 2019,
    semester: "봄학기",
    department: "전산학부",
    code_: 36.206,
    code: "CS206",
    class: "B",
    type: "전필",
    titleKor: "데이타구조",
    credits: 3,
    AU: 0,
    again: "N",
    grade: "B0",
    titleEng: "Data Structure",
    professor: "문은영",
  },
  {
    year: 2019,
    semester: "봄학기",
    department: "항공우주공학과",
    code_: "B8.210",
    code: "AE210",
    class: "",
    type: "전필",
    titleKor: "항공우주 열역학",
    credits: 3,
    AU: 0,
    again: "N",
    grade: "A-",
    titleEng: "Aerospace Thermodynamics",
    professor: "권세진",
  },
  {
    year: 2019,
    semester: "봄학기",
    department: "항공우주공학과",
    code_: "B8.230",
    code: "AE230",
    class: "",
    type: "전선",
    titleKor: "항공우주 재료역학",
    credits: 3,
    AU: 0,
    again: "N",
    grade: "A-",
    titleEng: "Mechanics of Aerospace Materials",
    professor: "이정률",
  },
  {
    year: 2019,
    semester: "봄학기",
    department: "항공우주공학과",
    code_: "B8.280",
    code: "AE280",
    class: "",
    type: "전선",
    titleKor: "항공우주 응용S/W",
    credits: 3,
    AU: 0,
    again: "N",
    grade: "A+",
    titleEng: "Software Application in Aerospace Engineering",
    professor: "신효상",
  },
  {
    year: 2019,
    semester: "봄학기",
    department: "항공우주공학과",
    code_: "B8.493",
    code: "AE493",
    class: "",
    type: "전선",
    titleKor: "항공우주공학 특강Ⅱ<기초항공프로젝트>",
    credits: 2,
    AU: 0,
    again: "N",
    grade: "B0",
    titleEng: "Special Lectures in Aerospace EngineeringⅡ<Introductory Aeronautical Projects>",
    professor: "이창훈",
  },
  {
    year: 2019,
    semester: "여름학기",
    department: "학과없음",
    code_: "NUAACC",
    code: "NUAACC",
    class: "",
    type: "자선",
    titleKor: "Chinese Culture (NUAA)",
    credits: 1,
    AU: 0,
    again: "N",
    grade: 98,
    titleEng: "Chinese Culture (NUAA)",
    professor: "",
  },
  {
    year: 2019,
    semester: "여름학기",
    department: "학과없음",
    code_: "NUAACS",
    code: "NUAACS",
    class: "",
    type: "자선",
    titleKor: "Chinese Speaking (NUAA)",
    credits: 1,
    AU: 0,
    again: "N",
    grade: 99,
    titleEng: "Chinese Speaking (NUAA)",
    professor: "",
  },
  {
    year: 2019,
    semester: "여름학기",
    department: "학과없음",
    code_: "NUAATM",
    code: "NUAATM",
    class: "",
    type: "자선",
    titleKor: "Training of Manufacturing Technology (NUAA)",
    credits: 2,
    AU: 0,
    again: "N",
    grade: 96,
    titleEng: "Training of Manufacturing Technology (NUAA)",
    professor: "",
  },
  {
    year: 2019,
    semester: "가을학기",
    department: "인문사회과학부",
    code_: 10.324,
    code: "HSS324",
    class: "",
    type: "인선",
    titleKor: "영미 과학영화",
    credits: 3,
    AU: 0,
    again: "N",
    grade: "B+",
    titleEng: "Science Fiction Cinema",
    professor: "다니엘 마틴",
  },
  {
    year: 2019,
    semester: "가을학기",
    department: "인문사회과학부",
    code_: 13.328,
    code: "HSS328",
    class: "",
    type: "인선",
    titleKor: "수학철학",
    credits: 3,
    AU: 0,
    again: "N",
    grade: "A0",
    titleEng: "Philosophy of Mathematics",
    professor: "박우석",
  },
  {
    year: 2019,
    semester: "가을학기",
    department: "항공우주공학과",
    code_: "B8.200",
    code: "AE200",
    class: "",
    type: "전선",
    titleKor: "기초 우주 프로젝트",
    credits: 3,
    AU: 0,
    again: "N",
    grade: "A0",
    titleEng: "Introductory Space Projects",
    professor: "이지윤",
  },
  {
    year: 2019,
    semester: "가을학기",
    department: "항공우주공학과",
    code_: "B8.208",
    code: "AE208",
    class: "",
    type: "전필",
    titleKor: "항공우주공학 실험 I",
    credits: 3,
    AU: 0,
    again: "N",
    grade: "A+",
    titleEng: "Aerospace Engineering Laboratory I",
    professor: "김규태",
  },
  {
    year: 2019,
    semester: "가을학기",
    department: "항공우주공학과",
    code_: "B8.220",
    code: "AE220",
    class: "",
    type: "전필",
    titleKor: "공기역학 I",
    credits: 3,
    AU: 0,
    again: "N",
    grade: "A+",
    titleEng: "Aerodynamics I",
    professor: "박기수",
  },
  {
    year: 2019,
    semester: "가을학기",
    department: "항공우주공학과",
    code_: "B8.250",
    code: "AE250",
    class: "",
    type: "전선",
    titleKor: "항공우주 동역학",
    credits: 3,
    AU: 0,
    again: "N",
    grade: "A0",
    titleEng: "Aerospace Dynamics",
    professor: "이창훈",
  },
  {
    year: 2020,
    semester: "봄학기",
    department: "ISAE-SUPAERO",
    code_: 0,
    code: "ISAE-SS1",
    class: "",
    type: "자선",
    titleKor: "Representation and Analysis of Dynamic Systems",
    credits: 0,
    AU: 0,
    again: "N",
    grade: "",
    titleEng: "Representation and Analysis of Dynamic Systems",
    professor: "",
  },
  {
    year: 2020,
    semester: "봄학기",
    department: "ISAE-SUPAERO",
    code_: 0,
    code: "ISAE-SS2",
    class: "",
    type: "자선",
    titleKor: "Introduction to Aircraft Structures",
    credits: 0,
    AU: 0,
    again: "N",
    grade: "",
    titleEng: "Introduction to Aircraft Structures",
    professor: "",
  },
  {
    year: 2020,
    semester: "봄학기",
    department: "ISAE-SUPAERO",
    code_: 0,
    code: "ISAE-SS3",
    class: "",
    type: "자선",
    titleKor: "French Language and Cultural Discovery",
    credits: 0,
    AU: 0,
    again: "N",
    grade: "",
    titleEng: "French Language and Cultural Discovery",
    professor: "",
  },
  {
    year: 2020,
    semester: "봄학기",
    department: "ISAE-SUPAERO",
    code_: 0,
    code: "ISAE-SS4",
    class: "",
    type: "자선",
    titleKor: "Aerodynamics and Propulsion",
    credits: 0,
    AU: 0,
    again: "N",
    grade: "",
    titleEng: "Aerodynamics and Propulsion",
    professor: "",
  },
  {
    year: 2020,
    semester: "봄학기",
    department: "ISAE-SUPAERO",
    code_: 0,
    code: "ISAE-SS5",
    class: "",
    type: "자선",
    titleKor: "Preliminary Aircraft Design",
    credits: 0,
    AU: 0,
    again: "N",
    grade: "",
    titleEng: "Preliminary Aircraft Design",
    professor: "",
  },
  {
    year: 2020,
    semester: "봄학기",
    department: "수리과학과",
    code_: 25.201,
    code: "MAS201",
    class: "I",
    type: "기선",
    titleKor: "응용미분방정식",
    credits: 3,
    AU: 0,
    again: "N",
    grade: "S",
    titleEng: "Differential Equations and Applications",
    professor: "이정욱",
  },
  {
    year: 2020,
    semester: "봄학기",
    department: "전산학부",
    code_: 36.23,
    code: "CS230",
    class: "",
    type: "전선",
    titleKor: "시스템프로그래밍",
    credits: 3,
    AU: 0,
    again: "N",
    grade: "A0",
    titleEng: "System Programming",
    professor: "김대영",
  },
  {
    year: 2020,
    semester: "봄학기",
    department: "전산학부",
    code_: 36.32,
    code: "CS320",
    class: "",
    type: "전필",
    titleKor: "프로그래밍언어",
    credits: 3,
    AU: 0,
    again: "N",
    grade: "B0",
    titleEng: "Programming Language",
    professor: "류석영",
  },
  {
    year: 2020,
    semester: "봄학기",
    department: "항공우주공학과",
    code_: "B8.300",
    code: "AE300",
    class: "",
    type: "전필",
    titleKor: "비행역학 프로젝트",
    credits: 3,
    AU: 0,
    again: "N",
    grade: "A-",
    titleEng: "Flight Mechanics Project",
    professor: "최한림, 박기수",
  },
  {
    year: 2020,
    semester: "봄학기",
    department: "항공우주공학과",
    code_: "B8.330",
    code: "AE330",
    class: "",
    type: "전필",
    titleKor: "항공우주 구조역학 I",
    credits: 3,
    AU: 0,
    again: "N",
    grade: "A+",
    titleEng: "Aerospace Structures I",
    professor: "조한솔",
  },
  {
    year: 2020,
    semester: "봄학기",
    department: "항공우주공학과",
    code_: "B8.370",
    code: "AE370",
    class: "",
    type: "전선",
    titleKor: "수치해석",
    credits: 3,
    AU: 0,
    again: "N",
    grade: "B+",
    titleEng: "Numerical Methods",
    professor: "이상봉",
  },
  {
    year: 2020,
    semester: "가을학기",
    department: "URP",
    code_: "08.490",
    code: "URP490",
    class: "AR",
    type: "연구",
    titleKor: "URP(학부생연구참여)(B)",
    credits: 3,
    AU: 0,
    again: "N",
    grade: "S",
    titleEng: "Undergraduate Research Participation(B)",
    professor: "이지윤",
  },
  {
    year: 2020,
    semester: "가을학기",
    department: "인문사회과학부",
    code_: 10.051,
    code: "HSS051",
    class: "D",
    type: "교필",
    titleKor: "체력육성",
    credits: 0,
    AU: 2,
    again: "N",
    grade: "S",
    titleEng: "Fitness Promotion",
    professor: "김석희",
  },
  {
    year: 2020,
    semester: "가을학기",
    department: "수리과학과",
    code_: 25.102,
    code: "MAS102",
    class: "M",
    type: "기필",
    titleKor: "미적분학Ⅱ",
    credits: 3,
    AU: 0,
    again: "Y",
    grade: "S",
    titleEng: "Calculus II",
    professor: "모라비토",
  },
  {
    year: 2020,
    semester: "가을학기",
    department: "전산학부",
    code_: 36.3,
    code: "CS300",
    class: "",
    type: "전필",
    titleKor: "알고리즘 개론",
    credits: 3,
    AU: 0,
    again: "N",
    grade: "A0",
    titleEng: "Introduction to Algorithms",
    professor: "최성희",
  },
  {
    year: 2020,
    semester: "가을학기",
    department: "항공우주공학과",
    code_: "B8.307",
    code: "AE307",
    class: "",
    type: "전필",
    titleKor: "항공우주공학 실험Ⅱ",
    credits: 3,
    AU: 0,
    again: "N",
    grade: "A0",
    titleEng: "Aerospace Engineering LaboratoryⅡ",
    professor: "박기수, 김천곤",
  },
  {
    year: 2020,
    semester: "가을학기",
    department: "항공우주공학과",
    code_: "B8.310",
    code: "AE310",
    class: "",
    type: "전선",
    titleKor: "추진기관",
    credits: 3,
    AU: 0,
    again: "N",
    grade: "A-",
    titleEng: "Propulsion System",
    professor: "김규태",
  },
  {
    year: 2020,
    semester: "가을학기",
    department: "항공우주공학과",
    code_: "B8.350",
    code: "AE350",
    class: "",
    type: "전선",
    titleKor: "항공우주 제어공학",
    credits: 3,
    AU: 0,
    again: "N",
    grade: "A-",
    titleEng: "Aerospace Control Engineering",
    professor: "최한림",
  },
  {
    year: 2021,
    semester: "봄학기",
    department: "전산학부",
    code_: 36.311,
    code: "CS311",
    class: "",
    type: "전필",
    titleKor: "전산기조직",
    credits: 3,
    AU: 0,
    again: "N",
    grade: "A-",
    titleEng: "Computer Organization",
    professor: "허재혁",
  },
  {
    year: 2021,
    semester: "봄학기",
    department: "전산학부",
    code_: 36.36,
    code: "CS360",
    class: "",
    type: "전선",
    titleKor: "데이타베이스 개론",
    credits: 3,
    AU: 0,
    again: "N",
    grade: "A0",
    titleEng: "Introduction to Database",
    professor: "김민수",
  },
  {
    year: 2021,
    semester: "봄학기",
    department: "항공우주공학과",
    code_: "B8.400",
    code: "AE400",
    class: "",
    type: "전필",
    titleKor: "항공우주 시스템 설계 I",
    credits: 3,
    AU: 0,
    again: "N",
    grade: "A0",
    titleEng: "Aerospace System Design I",
    professor: "최한림",
  },
  {
    year: 2021,
    semester: "봄학기",
    department: "항공우주공학과",
    code_: "B8.405",
    code: "AE405",
    class: "",
    type: "전선",
    titleKor: "인공위성 시스템",
    credits: 3,
    AU: 0,
    again: "N",
    grade: "A+",
    titleEng: "Satellite Systems",
    professor: "방효충",
  },
  {
    year: 2021,
    semester: "봄학기",
    department: "항공우주공학과",
    code_: "B8.410",
    code: "AE410",
    class: "",
    type: "전선",
    titleKor: "연소공학",
    credits: 3,
    AU: 0,
    again: "N",
    grade: "A0",
    titleEng: "Combustion Engineering",
    professor: "김규태",
  },
  {
    year: 2021,
    semester: "가을학기",
    department: "인문사회과학부",
    code_: 10.189,
    code: "HSS048",
    class: "C",
    type: "교필",
    titleKor: "체육동아리<STROKE>",
    credits: 0,
    AU: 2,
    again: "N",
    grade: "S",
    titleEng: "Extra Curricular Sport Activity<STROKE>",
    professor: "",
  },
  {
    year: 2021,
    semester: "가을학기",
    department: "인문사회과학부",
    code_: 13.317,
    code: "HSS317",
    class: "A",
    type: "인선",
    titleKor: "세계문학 특강<인문학으로서의 소설읽기>",
    credits: 3,
    AU: 0,
    again: "N",
    grade: "A-",
    titleEng:
      "Special Topics in World Literature<Reading Novels from the Perspective of Humanities>",
    professor: "전봉관",
  },
  {
    year: 2021,
    semester: "가을학기",
    department: "전산학부",
    code_: 36.33,
    code: "CS330",
    class: "",
    type: "전필",
    titleKor: "운영체제 및 실험",
    credits: 4,
    AU: 0,
    again: "N",
    grade: "B+",
    titleEng: "Operating Systems and Lab",
    professor: "신인식",
  },
  {
    year: 2021,
    semester: "가을학기",
    department: "전산학부",
    code_: 36.376,
    code: "CS376",
    class: "",
    type: "전선",
    titleKor: "기계학습",
    credits: 3,
    AU: 0,
    again: "N",
    grade: "A+",
    titleEng: "Machine Learning",
    professor: "황지영",
  },
  {
    year: 2021,
    semester: "가을학기",
    department: "전산학부",
    code_: 36.454,
    code: "CS454",
    class: "",
    type: "전선",
    titleKor: "인공 지능 기반 소프트웨어 공학",
    credits: 3,
    AU: 0,
    again: "N",
    grade: "A+",
    titleEng: "Artificial Intelligence Based Software Engineering",
    professor: "유신",
  },
  {
    year: 2021,
    semester: "가을학기",
    department: "전산학부",
    code_: 36.489,
    code: "CS489",
    class: "",
    type: "전선",
    titleKor: "컴퓨터윤리와사회문제",
    credits: 3,
    AU: 0,
    again: "N",
    grade: "S",
    titleEng: "Computer Ethics & Social issues",
    professor: "유신",
  },
  {
    year: 2022,
    semester: "봄학기",
    department: "전산학부",
    code_: 36.341,
    code: "CS341",
    class: "",
    type: "전선",
    titleKor: "전산망 개론",
    credits: 4,
    AU: 0,
    again: "N",
    grade: "A+",
    titleEng: "Introduction to Computer Networks",
    professor: "문수복",
  },
  {
    year: 2022,
    semester: "봄학기",
    department: "전산학부",
    code_: 36.38,
    code: "CS380",
    class: "",
    type: "전선",
    titleKor: "컴퓨터 그래픽스 개론",
    credits: 4,
    AU: 0,
    again: "N",
    grade: "A0",
    titleEng: "Introduction to Computer Graphics",
    professor: "박진아",
  },
  {
    year: 2022,
    semester: "봄학기",
    department: "디지털인문사회과학부",
    code_: 10.153,
    code: "HSS177",
    class: "",
    type: "자선",
    titleKor: "불어 회화",
    credits: 3,
    AU: 0,
    again: "N",
    grade: "A0",
    titleEng: "French Conversation",
    professor: "안화진",
  },
  {
    year: 2022,
    semester: "가을학기",
    department: "산업디자인학과",
    code_: 32.495,
    code: "ID495",
    class: "AG",
    type: "연구",
    titleKor: "개별연구",
    credits: 1,
    AU: 0,
    again: "N",
    grade: "S",
    titleEng: "Individual Study",
    professor: "석현정",
  },
]

// function getCourseIcon(code) {
//   switch(code) {
//     case "HSS022": case "HSS023": case "HSS024": case "HSS025": return "🇬🇧"
//     case "HSS062": return "🎵"
//     case "HSS090": case "HSS091": return "🐥";
//     case "HSS189": case "URP490": return "🚀";
//     case "HSS190": return "🔌";
//     case "NUAACC": case "NUAACS": case "NUAATM": return "🇨🇳";
//     case "HSS051": return "🏃‍♂️";
//     case "HSS177": return "🇫🇷";
//     case "HSS324": return "🎥";
//   }

//   switch(code.replace(/[^a-z]/gi, '')) {
//     case "AE": return "🚀";
//     case "CS": return "💻";
//     case "CH": return "🧪";
//     case "PH": return "🔭";
//     case "EE": return "🔌";
//     case "MAS": return "🧮";
//     case "BS": return "🧬";
//     case "IE": return "📈";
//     case "ISAESS": return "🛩";
//   }

//   return "📖";
// }
</script>

<template>
  <Page
    :supertitle="lang === 'ko' ? '학력 - 학사' : 'Education - B.S.'"
    :title="
      lang === 'ko' ? '한국과학기술원 (KAIST)' : 'Korea Advanced Institute of Science & Technology'
    "
    icon="KAIST.png"
  >
    <br />

    <div class="column-list" style="justify-content: flex-start">
      <div class="column" style="width: auto">
        <b>{{ lang === "ko" ? "졸업" : "Graduated" }}</b> (2018.02 - 2023.02)
      </div>

      <div v-if="lang === 'ko'" class="column" style="width: auto">
        <b>항공우주공학</b> 및 <b>전산학</b> 복수전공
      </div>
      <div v-else class="column" style="width: auto">
        <b>Aerospace Eng.</b> & <b>Computer Sci.</b> (double major)
      </div>

      <div class="column" style="width: auto">
        GPA&nbsp;&nbsp;<b>3.82</b> / 4.3&nbsp;&nbsp;({{ lang === "ko" ? "전공" : "major" }} AE
        <b>3.92</b>, CS <b>3.85</b>)
      </div>
    </div>

    <br />

    <p>
      -
      {{
        lang === "ko" ? "항공우주공학과 학생회 집행부원" : (
          "member of the undergraduate student council, dept. of AE"
        )
      }}
      (2019.03 - 2019.12)
    </p>

    <p>
      -
      {{
        lang === "ko" ? "KAIST 환경자치단체 G-inK" : (
          "G-inK: environmental self-governing body in KAIST"
        )
      }}
      (2018.09 - 2019.06)<br />
      -
      {{ lang === "ko" ? "학부 테니스 동아리 STROKE" : "STROKE: undergraduate tennis club" }}
      (2019.03 - 2021.12)
    </p>

    <br />
    <h3>
      {{
        lang === "ko" ? "수강 과목 목록 (153학점 8AU)" : "List of Courses Taken (153 credits, 8AU)"
      }}
    </h3>
    <table>
      <tr v-for="c in courses" :key="c.code">
        <th>
          <!-- {{getCourseIcon(c.code)}}&nbsp;&nbsp; -->
          {{ lang === "ko" ? c.titleKor : c.titleEng
          }}<span v-if="c.code.startsWith('ISAE')">*</span>&nbsp;<span class="color-subtitle"
            >({{ c.code }},
            {{
              lang === "ko" ? `${c.year ? c.year + "년 " : ""}${c.semester}`
              : c.year ?
                `${{ 봄학기: "Spring", 여름학기: "Summer", 가을학기: "Fall", 겨울학기: "Winter" }[c.semester]} ${c.year}`
              : "precomplete"
            }})</span
          >
        </th>
      </tr>
    </table>
    <p>
      &nbsp;&nbsp;*
      {{
        lang === "ko" ?
          "코로나-19 사태 악화로 인한 중도 귀국으로 미완수"
        : "dropped out due the COVID-19 global pandemic"
      }}
    </p>
  </Page>
</template>

<style scoped>
th {
  color: inherit;
}
</style>
