<template>
  <canvas id="chart"></canvas>
</template>

<script setup>
import { defineProps, onMounted, onUpdated, ref, inject, computed, watch } from "vue"
import { Chart } from "chart.js"

const { lang } = inject("lang")

const props = defineProps({ bodyWeights: Array })

const label = computed(() => (lang.value === "ko" ? "체중" : "Bodyweight"))
const chart = ref(null)

function makeChart() {
  const chartEl = document.getElementById("chart")
  if (!chartEl) return

  chart.value = new Chart(chartEl, {
    type: "line",
    options: {
      scales: {
        xAxes: [
          {
            type: "time",
          },
        ],
        yAxes: [
          {
            ticks: {
              min: 65,
              max: 75,
            },
          },
        ],
      },
    },
    data: {
      // labels: ["2015-03-15T13:03:00Z", "2015-03-25T13:02:00Z", "2015-04-25T14:12:00Z"],
      datasets: [
        {
          label: `${label.value} (kg)`,
          data: props.bodyWeights.map((record) => ({
            t: record.date.toISOString(),
            y: record.bodyWeight,
          })),
          backgroundColor: [
            "rgba(120,120,120,0.2)",
            // 'rgba(255, 99, 132, 0.2)',
            // 'rgba(54, 162, 235, 0.2)',
            // 'rgba(255, 206, 86, 0.2)',
            // 'rgba(75, 192, 192, 0.2)',
            // 'rgba(153, 102, 255, 0.2)',
            // 'rgba(255, 159, 64, 0.2)'
          ],
          borderColor: [
            "rgba(120,120,120,1)",
            // 'rgba(255,99,132,1)',
            // 'rgba(54, 162, 235, 1)',
            // 'rgba(255, 206, 86, 1)',
            // 'rgba(75, 192, 192, 1)',
            // 'rgba(153, 102, 255, 1)',
            // 'rgba(255, 159, 64, 1)'
          ],
          borderWidth: 2,
        },
      ],
    },
  })
}

onMounted(makeChart)
onUpdated(makeChart)
watch(label, makeChart)
</script>
