<script setup>
import { isMobile } from "@/main"
import Page from "../components/Page.vue"
import { usePostsStore } from "@/stores/posts"
import { onMounted, ref, inject, computed, watch } from "vue"
import { useRoute } from "vue-router"
import PostListElement from "../components/PostListElement.vue"

const { lang } = inject("lang")
const postsPerPage = 10
const postsStore = usePostsStore()
const route = useRoute()

const tag = ref(route.query.t)
const page = computed(() => parseInt(route.query.p || 1))

const title = computed(() => {
  if (route.query.t) return `#${route.query.t}`
  else return lang.value === "ko" ? "모든 게시물" : "All Posts"
})
watch(title, () => (document.title = title.value))

const postsTotal = computed(() =>
  route.query.t ? postsStore.posts.filter((p) => p.tag.includes(tag.value)) : postsStore.posts,
)

const posts = computed(() => {
  const posts = postsTotal.value.slice((page.value - 1) * postsPerPage, page.value * postsPerPage)
  posts.forEach((p) => {
    let imgs = p.text.match(/<img [^>]*src="[^"]*"[^>]*>/gm)
    if (imgs) p.img = imgs[0].replace(/.*src="([^"]*)".*/, "$1")
    else p.img = null
  })
  return posts
})
const totalPages = computed(() => Math.ceil(postsTotal.value.length / postsPerPage))

const tags = computed(() => {
  var tagmap = {}
  postsStore.posts.forEach((post) => {
    post.tag.forEach((t) => {
      tagmap[t] = (tagmap[t] || 0) + 1
    })
  })
  return Object.keys(tagmap)
    .map((t) => [t, tagmap[t]])
    .sort((a, b) => b[1] - a[1])
})

const guideText = computed(() => {
  // if (posts.length===0) return lang.value==='ko' ? "게시물을 불러오는 중입니다..." : "Loading posts...";
  if (posts.value.length == 0) return lang === "ko" ? "검색 결과가 없습니다." : "No results found."
  else return ""
})

onMounted(() => {
  postsStore.fetchPosts()
})
</script>

<template>
  <Page v-if="posts != []" :title="title">
    <!-- :icon="route.query.t ? `🏷` : null" -->
    <!-- <a href="">작성</a> / <a href="">최종 수정</a> -->

    <div v-if="route.query.t == undefined">
      <ul v-if="isMobile()" class="toggle">
        <li>
          <details>
            <summary>태그</summary>
            <p>
              <span v-for="t in tags" :key="t[0]">
                <router-link :to="`/p?t=${t[0]}`"> #{{ t[0] }}({{ t[1] }}) </router-link>
                &nbsp;
              </span>
            </p>
          </details>
        </li>
      </ul>
      <p v-else>
        <span v-for="t in tags" :key="t[0]">
          <router-link :to="`/p?t=${t[0]}`"> #{{ t[0] }}({{ t[1] }}) </router-link>
          &nbsp;
        </span>
      </p>
    </div>
    <br />
    <div v-if="posts.length != 0" style="width: min(100%, 700px)">
      <PostListElement v-for="p in posts" :key="p.id" :p="p" :t="tag" />
      <div style="text-align: center">
        <router-link v-if="page > 1" :to="`/p?${tag ? `t=${tag}&` : ''}p=${page - 1}`">
          ← {{ { ko: "이전", en: "prev" }[lang] }}
        </router-link>
        &nbsp;
        <span v-for="p in totalPages" :key="p">
          <router-link
            :to="`/p?${tag ? `t=${tag}&` : ''}p=${p}`"
            :style="{ 'font-weight': p == page ? 'bold' : 'regular' }"
          >
            {{ p }}
          </router-link>
          &nbsp;
        </span>
        <router-link v-if="page < totalPages" :to="`/p?${tag ? `t=${tag}&` : ''}p=${page + 1}`">
          {{ { ko: "다음", en: "next" }[lang] }} →
        </router-link>
      </div>
    </div>
    <p v-if="posts.length == 0">{{ guideText }}</p>
    <br />
    <p v-if="route.query.t">
      <router-link :to="`/p`">{{
        { ko: "모든 게시물 보기", en: "see all posts" }[lang]
      }}</router-link>
    </p>
  </Page>
</template>
