import { createRouter, createWebHistory } from "vue-router"
import Main from "../views/Main.vue"
import Life from "../views/Life.vue"
import Checkpoints from "../views/Checkpoints.vue"
import Post from "../views/Post.vue"
import PostList from "../views/PostList.vue"
import Newsletter from "../views/Newsletter.vue"
import NewsletterVerify from "../views/NewsletterVerify.vue"
import NewsletterUnsubscribe from "../views/NewsletterUnsubscribe.vue"
import NewsletterUnsubscribed from "../views/NewsletterUnsubscribed.vue"
import NewsletterSend from "../views/NewsletterSend.vue"
import Link from "../views/Link.vue"
import Test from "../views/Test.vue"
import Code404 from "../views/Code404.vue"
import KAIST from "../views/KAIST.vue"
import Portfolio from "../views/Portfolio.vue"
import Freelancing from "../views/Freelancing.vue"
import Workouts from "../views/Workouts.vue"
import Climbs from "../views/Climbs.vue"
import Burgers from "../views/Burgers.vue"

const routes = [
  {
    path: "/",
    alias: ["/index.html"],
    name: "Main",
    component: Main,
  },
  {
    path: "/life",
    name: "Life",
    component: Life,
  },
  {
    path: "/ckpts",
    name: "Checkpoints",
    component: Checkpoints,
  },
  {
    path: "/p/:id",
    name: "Post",
    component: Post,
    props: true,
  },
  {
    path: "/p",
    name: "PostList",
    component: PostList,
  },

  {
    path: "/l/:target",
    name: "Link",
    component: Link,
  },
  {
    path: "/test",
    name: "Test",
    component: Test,
  },
  {
    path: "/newsletter",
    name: "Newsletter",
    component: Newsletter,
  },
  {
    path: "/newsletter/subscribe",
    name: "NewsletterSubscribe",
    component: Newsletter,
  },
  {
    path: "/newsletter/verify",
    name: "NewsletterVerify",
    component: NewsletterVerify,
  },
  {
    path: "/newsletter/unsubscribe",
    name: "NewsletterUnsubscribe",
    component: NewsletterUnsubscribe,
  },
  {
    path: "/newsletter/unsubscribed",
    name: "NewsletterUnsubscribed",
    component: NewsletterUnsubscribed,
  },
  {
    path: "/newsletter/send",
    name: "NewsletterSend",
    component: NewsletterSend,
  },
  {
    path: "/ac/kaist",
    name: "KAIST",
    component: KAIST,
  },
  {
    path: "/portfolio",
    name: "Portfolio",
    component: Portfolio,
  },
  {
    path: "/freelancing",
    name: "Freelancing",
    component: Freelancing,
  },
  {
    path: "/wrkts",
    name: "Workouts",
    component: Workouts,
  },
  {
    path: "/clmbs",
    name: "Climbs",
    component: Climbs,
  },
  {
    path: "/brgrs",
    name: "Burgers",
    component: Burgers,
  },
  {
    path: "/:anything(.*)",
    component: Code404,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0, behavior: "smooth" }
  },
})

export default router
