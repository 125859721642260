<script setup>
document.title = "Climbs"

import { onMounted, onUpdated, inject, computed } from "vue"
import Page from "../components/Page.vue"

const { lang } = inject("lang")

const climbs = [
  {
    brand: "더클라임",
    branch: "마곡",
    date: [2022, 2, 21],
    with: [],
    solved: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    stock: [-1, -1, -1, -1, -1, -1, -1, -1, -1, -1],
  },
  {
    brand: "더클라임",
    branch: "일산",
    date: [2022, 2, 23],
    with: ["김혜민"],
    solved: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    stock: [-1, -1, -1, -1, -1, -1, -1, -1, -1, -1],
  },
  {
    brand: "베이스캠프볼더스팟",
    branch: "",
    date: [2022, 3, 16],
    with: [],
    solved: [0, 0, 0, 0, 0, 0, 0, 0],
    stock: [-1, -1, -1, -1, -1, -1, -1, -1],
  },
  {
    brand: "더클라임",
    branch: "양재",
    date: [2022, 3, 28],
    with: ["김혜민"],
    solved: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    stock: [-1, -1, -1, -1, -1, -1, -1, -1, -1, -1],
  },
  {
    brand: "더클라임",
    branch: "신림",
    date: [2022, 4, 3],
    with: [],
    solved: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    stock: [-1, -1, -1, -1, -1, -1, -1, -1, -1, -1],
  },
  {
    brand: "더클라임",
    branch: "홍대",
    date: [2022, 4, 9],
    with: ["김혜민"],
    solved: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    stock: [-1, -1, -1, -1, -1, -1, -1, -1, -1, -1],
  },
  {
    brand: "더클라임",
    branch: "신림",
    date: [2022, 4, 15],
    with: ["김혜민"],
    solved: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    stock: [-1, -1, -1, -1, -1, -1, -1, -1, -1, -1],
  },
  {
    brand: "더클라임",
    branch: "연남",
    date: [2022, 5, 20],
    with: ["임진호", "홍지우", "최지우"],
    solved: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    stock: [-1, -1, -1, -1, -1, -1, -1, -1, -1, -1],
  },
  {
    brand: "베이스캠프볼더스팟",
    branch: "",
    date: [2022, 5, 26],
    with: ["유현욱"],
    solved: [0, 0, 0, 0, 0, 0, 0, 0],
    stock: [-1, -1, -1, -1, -1, -1, -1, -1],
  },
  {
    brand: "클라이밍파크",
    branch: "한티",
    date: [2022, 5, 28],
    with: ["황다예"],
    solved: [0, 0, 0, 0, 0, 0, 0, 0],
    stock: [-1, -1, -1, -1, -1, -1, -1, -1],
  },
  {
    brand: "베이스캠프볼더스팟",
    branch: "",
    date: [2022, 6, 1],
    with: [],
    solved: [0, 0, 0, 0, 0, 0, 0, 0],
    stock: [-1, -1, -1, -1, -1, -1, -1, -1],
  },
  {
    brand: "더클라임",
    branch: "양재",
    date: [2022, 6, 4],
    with: ["홍지우"],
    solved: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    stock: [-1, -1, -1, -1, -1, -1, -1, -1, -1, -1],
  },
  {
    brand: "베이스캠프볼더스팟",
    branch: "",
    date: [2022, 6, 14],
    with: [],
    solved: [0, 0, 0, 0, 0, 0, 0, 0],
    stock: [-1, -1, -1, -1, -1, -1, -1, -1],
  },
  {
    brand: "더플라스틱",
    branch: "염창",
    date: [2022, 6, 28],
    with: ["이일석"],
    solved: [0, 0, 0, 0, 0, 0, 0, 0],
    stock: [-1, -1, -1, -1, -1, -1, -1, -1],
  },
  {
    brand: "피커스",
    branch: "종로",
    date: [2022, 6, 30],
    with: ["최지우", "이정진", "박시안"],
    solved: [0, 0, 0, 0, 0, 0, 0, 0, 0],
    stock: [-1, -1, -1, -1, -1, -1, -1, -1, -1],
  },
  {
    brand: "더클라임",
    branch: "마곡",
    date: [2022, 7, 3],
    with: ["남성훈", "한주연"],
    solved: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    stock: [-1, -1, -1, -1, -1, -1, -1, -1, -1, -1],
  },
  {
    brand: "더클라임",
    branch: "신림",
    date: [2022, 7, 29],
    with: ["김혜민", "김승민"],
    solved: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    stock: [-1, -1, -1, -1, -1, -1, -1, -1, -1, -1],
  },
  {
    brand: "베이스캠프볼더스팟",
    branch: "",
    date: [2022, 8, 2],
    with: [],
    solved: [0, 0, 0, 0, 0, 0, 0, 0],
    stock: [-1, -1, -1, -1, -1, -1, -1, -1],
  },
  {
    brand: "더클라임",
    branch: "서울대",
    date: [2022, 8, 5],
    with: ["김혜민", "한종혁"],
    solved: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    stock: [-1, -1, -1, -1, -1, -1, -1, -1, -1, -1],
  },
  {
    brand: "더클라임",
    branch: "마곡",
    date: [2022, 8, 8],
    with: ["김혜민"],
    solved: [0, 0, 0, 0, 7, 0, 0, 0, 0, 0],
    stock: [-1, -1, -1, -1, -1, -1, -1, -1, -1, -1],
  },
  {
    brand: "더클라임",
    branch: "연남",
    date: [2022, 8, 12],
    with: [],
    solved: [0, 0, 0, 0, 3, 0, 0, 0, 0, 0],
    stock: [-1, -1, -1, -1, -1, -1, -1, -1, -1, -1],
  },
  {
    brand: "클라이밍파크",
    branch: "신논현",
    date: [2022, 8, 15],
    with: ["최지우"],
    solved: [0, 0, 0, 12, 6, 0, 0, 0],
    stock: [-1, -1, -1, 14, 14, -1, -1, -1],
  },
  {
    brand: "베이스캠프볼더스팟",
    branch: "",
    date: [2022, 8, 24],
    with: [],
    solved: [0, 0, 0, 4, 0, 0, 0, 0],
    stock: [-1, -1, -1, -1, -1, -1, -1, -1],
  },
  {
    brand: "베이스캠프볼더스팟",
    branch: "",
    date: [2022, 8, 29],
    with: [],
    solved: [0, 0, 0, 12, 0, 0, 0, 0],
    stock: [-1, -1, -1, -1, -1, -1, -1, -1],
  },
  {
    brand: "클라이밍파크",
    branch: "신논현",
    date: [2022, 9, 14],
    with: ["김혜민", "신상윤"],
    solved: [0, 0, 0, 0, 5, 0, 0, 0],
    stock: [-1, -1, -1, -1, -1, -1, -1, -1],
  },
  {
    brand: "더클라임",
    branch: "마곡",
    date: [2022, 9, 18],
    with: [],
    solved: [0, 0, 0, 0, 10, 0, 0, 0, 0, 0],
    stock: [-1, -1, -1, -1, -1, -1, -1, -1, -1, -1],
  },
  {
    brand: "더클라임",
    branch: "양재",
    date: [2022, 9, 22],
    with: ["남성훈", "이혜연"],
    solved: [0, 0, 0, 0, 7, 0, 0, 0, 0, 0],
    stock: [-1, -1, -1, -1, -1, -1, -1, -1, -1, -1],
  },
  {
    brand: "훅클라이밍",
    branch: "왕십리",
    date: [2022, 9, 24],
    with: ["김지영"],
    solved: [0, 0, 0, 0, 0, 0, 0],
    stock: [-1, -1, -1, -1, -1, -1, -1],
  },
  {
    brand: "더클라임",
    branch: "연남",
    date: [2022, 9, 26],
    with: [],
    solved: [0, 0, 0, 30, 9, 0, 0, 0, 0, 0],
    stock: [-1, -1, -1, 30, -1, -1, -1, -1, -1, -1],
  },
  {
    brand: "클라이밍짐리드",
    branch: "유성",
    date: [2022, 9, 29],
    with: [],
    solved: [],
    stock: [],
  },
  {
    brand: "클라이밍파크",
    branch: "한티",
    date: [2022, 10, 4],
    with: [],
    solved: [0, 0, 0, 15, 8, 0, 0, 0],
    stock: [-1, -1, -1, 15, 14, -1, -1, -1],
  },
  {
    brand: "더클라임",
    branch: "일산",
    date: [2022, 10, 8],
    with: [],
    solved: [0, 0, 0, 0, 13, 0, 0, 0, 0, 0],
    stock: [-1, -1, -1, -1, -1, -1, -1, -1, -1, -1],
  },
  {
    brand: "클라이밍파크",
    branch: "신논현",
    date: [2022, 10, 10],
    with: [],
    solved: [0, 0, 0, 14, 9, 1, 0, 0],
    stock: [-1, -1, -1, 14, 14, -1, -1, -1],
    comment: "첫 클팍 갈색!",
  },
  {
    brand: "Basecamp Climbing",
    branch: "Queen West",
    date: [2022, 10, 24],
    with: [],
    solved: [0, 9, 1, 0, 0, 0, 0],
    stock: [-1, 10, -1, -1, -1, -1, -1],
    comment:
      "외국(토론토, 캐나다) 클라이밍장 첫 방문! 외국은 한국과 뭔가 다른 점이 있을까 궁금해 가봤는데 난이도 표시 방법, 홀드 생김새 등 모든 부분에서 한국과 큰 차이가 없었다. 다만 난이도가 전체적으로 상향되어있다는 느낌은 있었다. 한국에선 보통 딱 중간 정도 난이도까지는 풀어내는데 여기에선 7단계 중 3단계 하나를 겨우 풀었으니. 또 깊게 파여있지 않은 홀드가 많아서 악력을 많이 요구했다.",
  },
  {
    brand: "더클라임",
    branch: "마곡",
    date: [2022, 11, 17],
    with: [],
    solved: [1, 0, 1, 25, 14, 0, 0, 0, 0, 0],
    stock: [-1, -1, -1, 27, 29, -1, -1, -1, -1, -1],
  },
  {
    brand: "더플라스틱",
    branch: "염창",
    date: [2022, 11, 26],
    with: ["김혜민", "신상윤"],
    solved: [1, 1, 1, 3, 7, 4, 0, 0],
    stock: [-1, -1, -1, -1, -1, -1, -1, -1],
    comment: "격리해제일, 아직 컨디션이 완전히 돌아오지 못한 듯하다.",
  },
  {
    brand: "더클라임",
    branch: "서울대",
    date: [2022, 12, 2],
    with: [],
    solved: [1, 1, 1, 2, 7, 0, 0, 0, 0, 0],
    stock: [-1, -1, -1, -1, -1, -1, -1, -1, -1, -1],
    comment:
      "한 빨강 문제에 한 시간을 매달렸는데 결국 못 풀었다. 스타트에서 중간까지 그리고 중간에서 탑까지 각각은 하는데 한번에 연결이 안 된다. 여기에 힘 다 빼서 파랑도 일곱 개밖에..",
  },
  {
    brand: "더클라임",
    branch: "연남",
    date: [2022, 12, 8],
    with: ["이찬희"],
    solved: [1, 0, 0, 5, 12, 0, 0, 0, 0, 0],
    stock: [-1, -1, -1, -1, -1, -1, -1, -1, -1, -1],
    comment:
      "컨디션이 별로인 줄 알았는데 세어보니 파랑 많이 풀었더라. 그냥 몸이 늦게 풀린 듯. 빨강은 오늘은 시도도 몇 개 안 해봤다. 동생도 데려가봤으니 이제 남은 건 어무니뿐?..",
  },
  {
    brand: "더클라임",
    branch: "신림",
    date: [2022, 12, 13],
    with: [],
    solved: [1, 1, 1, 1, 8, 0, 0, 0, 0, 0],
    stock: [-1, -1, -1, -1, -1, -1, -1, -1, -1, -1],
    comment: "",
  },
  {
    brand: "더플라스틱",
    branch: "염창",
    date: [2022, 12, 18],
    with: [],
    solved: [1, 1, 1, 1, 1, 2, 0, 0],
    stock: [-1, -1, -1, -1, -1, -1, -1, -1],
    comment: "",
  },
  {
    brand: "Stonegoat Climbing Gym",
    branch: "",
    date: [2022, 12, 23],
    with: [],
    solved: [1, 1, 2, 7, 0, 0, 0],
    stock: [-1, -1, -1, -1, -1, -1, -1],
    comment:
      "어느덧 클라이밍장 방문 3개국째. 지금껏 방문했던 어떤 클라이밍장보다도 넓었다. 그러나 면적만큼 문제 수가 많진 않은 것 같다. 여기는 문제마다 붙어있는 스타트 라벨에 QR코드가 있던데(!) 유효한 url로 연결되지 않아서 정확히 무얼 위한 것인지는 알아내지 못했다.",
  },
  {
    brand: "더클라임",
    branch: "연남",
    date: [2022, 12, 29],
    with: ["한종혁", "김혜민"],
    solved: [1, 1, 1, 2, 6, 0, 0, 0, 0, 0],
    stock: [-1, -1, -1, -1, -1, -1, -1, -1, -1, -1],
    comment:
      "(이전에 해결한 파랑 2개 포함) 오늘도 주제를 모르고 빨강 풀어보겠다고 까불다가 전완 다 털려서 새로운 파랑 겨우 네 개 풀었다. 내 근육이 이틀 내에 완전 회복될 리 만무하니 빨강 하나를 풀어보겠다는 연내 목표는 사실상 실패! 이미 빨강 3개에서 하향 조정했던 목표인데.. 일단 새 목표는 23년 1월 내 빨강 1개. 당분간 헬스에나 더 매진해야 할지 생각이 많아지는 밤이다 호호",
  },
  {
    brand: "베이스캠프볼더스팟",
    branch: "",
    date: [2023, 2, 21],
    with: ["박민규", "이채영", "이상화", "서성환"],
    solved: [1, 1, 1, 4, 1, 1, 0, 0],
    stock: [-1, -1, -1, -1, -1, -1, -1, -1],
  },
  {
    brand: "베이스캠프볼더스팟",
    branch: "",
    date: [2023, 3, 5],
    with: ["우다연"],
    solved: [2, 1, 2, 2, 2, 0, 0, 0],
    stock: [-1, -1, -1, -1, -1, -1, -1, -1],
  },
  {
    brand: "베이스캠프볼더스팟",
    branch: "",
    date: [2023, 4, 2],
    with: ["변경호"],
    solved: [1, 1, 1, 1, 3, 0, 0, 0],
    stock: [-1, -1, -1, -1, -1, -1, -1, -1],
  },
  {
    brand: "더클라임",
    branch: "마곡",
    date: [2023, 4, 7],
    with: [],
    solved: [1, 1, 1, 1, 4, 0, 0, 0, 0, 0],
    stock: [-1, -1, -1, -1, -1, -1, -1, -1, -1, -1],
  },
  {
    brand: "더클라임",
    branch: "마곡",
    date: [2023, 4, 11],
    with: ["김혜민"],
    solved: [1, 1, 1, 3, 9, 0, 0, 0, 0, 0],
    stock: [-1, -1, -1, -1, -1, -1, -1, -1, -1, -1],
  },
  {
    brand: "더클라임",
    branch: "연남",
    date: [2023, 4, 22],
    with: [],
    solved: [1, 1, 1, 1, 4, 1, 0, 0, 0, 0],
    stock: [-1, -1, -1, -1, -1, -1, -1, -1, -1, -1],
  },
  {
    brand: "베이스캠프볼더스팟",
    branch: "",
    date: [2023, 4, 27],
    with: ["권혁민"],
    solved: [1, 1, 1, 1, 1, 1, 0, 0],
    stock: [-1, -1, -1, -1, -1, -1, -1, -1],
  },
].reverse()

const brands = {
  더클라임: {
    levels: [
      "white",
      "yellow",
      "darkorange",
      "green",
      "darkblue",
      "red",
      "darkorchid",
      "gray",
      "brown",
      "black",
    ],
    instagram: "theclimb_ilsan",
  },
  클라이밍파크: {
    levels: ["yellow", "pink", "blue", "red", "purple", "brown", "gray", "black"],
    instagram: "climbing_park",
  },
  더플라스틱: {
    levels: ["red", "orange", "yellow", "green", "deepskyblue", "blue", "purple", "black"],
    instagram: "theplastik_yeomchang",
  },
  베이스캠프볼더스팟: {
    levels: ["white", "red", "orange", "yellow", "teal", "lightskyblue", "mediumorchid", "black"],
    instagram: "basecamp_daejeon",
  },
  피커스: {
    levels: [
      "red",
      "orange",
      "yellow",
      "teal",
      "deepskyblue",
      "blue",
      "navy",
      "slateblue",
      "aliceblue",
    ],
    instagram: "peakers_jongro",
  },
  훅클라이밍: {
    levels: ["red", "orange", "yellow", "lime", "deepskyblue", "blue", "purple"],
    instagram: "hook_wangsimni",
  },
  "Stonegoat Climbing Gym": {
    levels: ["red", "orangered", "yellow", "mediumseagreen", "dodgerblue", "indigo", "black"],
    instagram: "stonegoatclimb",
  },
  "Basecamp Climbing": {
    levels: ["yellow", "orange", "red", "green", "blue", "mediumvioletred", "black"],
    instagram: "basecampclimbing_qw",
  },
}

const sum = (a1, a2) => a1.map((v, i) => v + a2[i])
// function flattenProblems(problems) {
//   return Object.keys(problems).reduce((acc, color) => {
//     return acc.concat(Array(problems[color]).fill(color));
//   }, []);
// }

const elapsed = computed(() => {
  const today = new Date()
  const start = new Date(2022, 1, 21)
  let years = today.getFullYear() - start.getFullYear()
  let months = today.getMonth() - start.getMonth()
  if (months < 0) {
    years -= 1
    months += 12
  }
  const yearsString =
    years ?
      `${years}${
        lang.value === "ko" ? "년"
        : years === 1 ? "yr"
        : "yrs"
      }`
    : ""
  const monthsString =
    months ?
      `${months}${
        lang.value === "ko" ? "개월"
        : months === 1 ? "mo"
        : "mos"
      }`
    : ""
  return yearsString + ((years || months) && " ") + monthsString
})

onMounted(() => {
  for (const brand in brands) {
    brands[brand].solved = Array(brands[brand].levels.length).fill(0)
  }

  climbs.forEach((climb) => {
    if (climb.brand in brands)
      brands[climb.brand].solved = sum(brands[climb.brand].solved, climb.solved)
  })
})

onUpdated(() => {
  var url
  var scrollEl
  if (location.href.match(/#[a-zA-Z0-9_]+$/)) {
    const ex = /(.+)(#[a-zA-Z0-9_]+)$/.exec(location.href)
    url = ex[1]
    scrollEl = ex[2]
  } else url = location.href

  const as = document.querySelectorAll("#texttt a")

  for (let a of as) {
    // if href of a starts with href and followed by alphanumeric characters
    if (a.href.startsWith(url) && a.href.replace(url, "").match(/^#[a-zA-Z0-9_]+$/)) {
      a.addEventListener("click", function (e) {
        e.preventDefault()
        document.querySelector(a.href.replace(url, "")).scrollIntoView({
          behavior: "smooth",
        })
      })
    }
  }

  if (scrollEl) {
    document.querySelector(scrollEl).scrollIntoView({
      behavior: "smooth",
    })
  }
})
</script>

<template>
  <Page icon="hold.svg" title="Climbs">
    <p v-if="lang === 'ko'">
      볼더링 입문 2022.02.21., <b>{{ elapsed }}</b
      >간 <b>{{ climbs.length }}회</b> 방문<br />
      일지 작성 2022.08.08. ~
    </p>
    <p v-else-if="lang === 'en'">
      Enjoying bouldering since Feb 21st 2022, climbed
      <b>{{ climbs.length }} times</b> in <b>{{ elapsed }}</b
      >.<br />
      Journaling since Aug 8th 2022.
    </p>

    <!-- <p>
      <span style="text-decoration: line-through;">2022 연내 목표: 더클라임 빨강 완등</span> <span style="color: red;">실패!</span><br>
      <span style="text-decoration: line-through;">2023년 1월 내 목표: 더클라임 빨강 완등</span> 손목 회복 위해 잠정 중단 (2022.12.29.~)
    </p> -->

    <p>
      {{ { ko: "신장", en: "height" }[lang] }} <b>179cm</b> / wingspan
      <b>185cm</b>
    </p>

    <div class="column-list">
      <div class="column column-2">
        <h3>
          {{ { ko: "누적", en: "In Total" }[lang] }}&nbsp;<span
            style="font-weight: 400; font-size: 80%"
            class="color-subtitle"
          ></span>
        </h3>

        <div class="climbs">
          <div class="climb" v-for="b in Object.keys(brands)" v-bind:key="b" :id="`${b}`">
            <div style="font-weight: 500">
              <a :href="`https://instagram.com/${brands[b].instagram}`" target="_blank">{{ b }}</a>
              <br />
              <div style="font-size: 50%; vertical-align: 2px; margin-top: 5px">
                <span v-for="(l, i) in brands[b].levels" v-bind:key="l">
                  <div class="problem" :style="`background-color: ${l};`"></div>
                  <span v-if="i < brands[b].levels.length - 1">→</span>
                </span>
              </div>
            </div>

            <div v-if="brands[b].solved" style="margin-top: 4px" class="problems">
              <span v-for="(l, i) in brands[b].levels" v-bind:key="l">
                <div
                  class="problem"
                  :style="`background-color: ${l}; margin-right: 2px;`"
                  v-for="_ in brands[b].solved[i]"
                  v-bind:key="_"
                ></div>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="column column-2">
        <h3>{{ { ko: "일별", en: "Daily" }[lang] }}</h3>
        <div class="climbs">
          <div
            class="climb"
            v-for="c in climbs"
            v-bind:key="c.date"
            :id="`d${c.date[0]}-${String(c.date[1]).padStart(2, '0')}-${String(c.date[2]).padStart(2, '0')}`"
          >
            <div style="font-weight: 500">
              {{ c.brand }}<span v-if="c.branch">&nbsp;{{ c.branch }}</span
              >&nbsp;<span class="color-subtitle subscript">
                {{
                  `${c.date[0]}.${String(c.date[1]).padStart(2, "0")}.${String(c.date[2]).padStart(2, "0")}.`
                }}&nbsp;<span v-for="p in ['이건희'].concat(c.with)" v-bind:key="p" :title="p">
                  <svg viewBox="0 0 512 512" style="width: 10px; height: 10px; margin-right: 2px">
                    <g fill="#999999">
                      <path
                        d="M256,256c70.5,0,128-57.5,128-128S326.5,0,256,0S128,57.5,128,128C128.9,198.5,185.5,256,256,256z M256,320C170.7,320,0.9,362.7,0.9,448v64h510.1v-64C511.1,362.7,341.3,320,256,320z"
                      />
                    </g>
                  </svg>
                </span>
                <!-- <span v-if="c.with && c.with.length">w/ {{c.with.join(', ')}}</span> -->
              </span>
            </div>
            <div v-if="c.brand in brands" style="margin-top: 2px" class="problems">
              <span v-for="(l, i) in brands[c.brand].levels" v-bind:key="l">
                <div
                  class="problem"
                  :style="`background-color: ${l}; margin-right: 2px;`"
                  v-for="_ in c.solved[i]"
                  v-bind:key="_"
                ></div>
              </span>
            </div>
            <div style="line-height: 1.5; text-align: justify; margin-top: 8px" v-if="c.comment">
              {{ c.comment }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <br> -->
    <!-- <b>* 클라이밍장 체인별 난이도</b> (어려워지는 순서) -->
    <!-- <div v-for="b in brands" v-bind:key="b.name" style="line-height: 20px; margin-top: 5px;">
      <span style="font-weight: 500; margin-right: 3px;">
        {{ b.name }}
      </span>
      <svg viewBox="0 0 16 16" style="width: 20px; height: 20px; margin-right: 1px; vertical-align: -4px;" v-for="l in b.levels" v-bind:key="l">
        <circle cx="8" cy="8" r="8" style="fill: black"/>
        <circle cx="8" cy="8" r="6.5" :style="`fill: ${l}`"/>
      </svg>  
    </div> -->
    <!-- <table style="border: none; border-collapse: separate; border-spacing: 0 4px;">
      <tr v-for="b in brands" v-bind:key="b.name" style="line-height: 20px;">
        <td style="font-weight: 500; border: none; border-left: 4px solid black; padding: 0 10px 0 4px;">
          {{ b.name }}
        </td>
        <td style="padding: 0; display: flex; justify-content: space-between; border: none;">
          <svg viewBox="0 0 16 16" style="width: 20px; height: 20px; margin-right: 1px; vertical-align: -4px;" v-for="l in b.levels" v-bind:key="l">
            <circle cx="8" cy="8" r="8" style="fill: black"/>
            <circle cx="8" cy="8" r="6.5" :style="`fill: ${l}`"/>
          </svg>
        </td>
      </tr>
    </table> -->
  </Page>
</template>

<style scoped>
/* body {
    background-color: #eee;
  } */
.climb {
  line-height: 1;
  margin-top: 12px;
  border-left: 4px solid black;
  padding-left: 6px;
}
.climb:first-child {
  margin-top: 8px;
}
.problem {
  width: 15px;
  height: 15px;
  border-radius: 4px;
  /* border: 1px solid black; */
  display: inline-block;
  vertical-align: -4.5px;
}
/* .problems {
    background-color: #ddd; border-radius: 4px; padding: 2px 4px;
  } */
</style>
