<script setup>
const list = {
  instagram: "https://instagram.com/gunhee_yi",
  github: "https://github.com/GunheeYi",
  email: "mailto:gunny@kaist.ac.kr",
}
import { onMounted, ref, inject } from "vue"
import { useRoute } from "vue-router"
import Page from "../components/Page.vue"

const { lang } = inject("lang")

const route = useRoute()

const target = ref(null)

onMounted(() => {
  if (list[route.params.target] != undefined) {
    target.value = list[route.params.target]
    setTimeout(() => {
      window.location.href = target.value
    }, 1000)
  }
})
</script>

<template>
  <Page :title="lang === 'ko' ? '자동 리다이렉트' : 'Auto-redirect'">
    <p v-if="target">
      {{
        {
          ko: `${target}(으)로 곧 이동합니다...`,
          en: `Moving to ${target} any moment...`,
        }[lang]
      }}
    </p>
    <p v-else>
      {{ { ko: "타겟 URL을 찾을 수 없습니다.", en: "Cannot find target URL." }[lang] }}
    </p>
  </Page>
</template>
