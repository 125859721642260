<script setup>
import { defineProps, defineEmits, onMounted, ref, watch, inject } from "vue"
import { Timeline, DataSet } from "vis-timeline/standalone"

const { lang } = inject("lang")

const props = defineProps(["groups", "items", "itemIdSelected"])
const emits = defineEmits(["setEventIdSelected", "setDateSelected"])

const timeline = ref(null)

watch([timeline, props], () => {
  if (timeline.value) timeline.value.setItems(new DataSet(props.items))
})

onMounted(() => {
  var container = document.getElementById("visualization")
  timeline.value = new Timeline(container, new DataSet([]), {
    min: new Date(1999, 4, 5),
    max: new Date(2149, 12, 31),
    margin: {
      item: {
        horizontal: 0,
      },
    },
    stack: false,
    stackSubgroups: false,
  })
  timeline.value.on("click", (e) => {
    if (e.item) {
      emits("setEventIdSelected", e.item)
      emits("setDateSelected", null)
    } else if (e.time) {
      emits("setDateSelected", new Date(e.time))
      emits("setEventIdSelected", null)
    }
  })
})
</script>

<template>
  <p class="color-subtitle">
    {{
      lang === "ko" ?
        "사건을 눌러 상세 정보를, 빈 공간을 눌러 해당 날짜의 사건을 확인할 수 있습니다."
      : "Click an event for details, or an empty space for events on that date."
    }}
  </p>
  <div id="visualization"></div>
</template>

<style></style>
