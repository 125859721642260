<script setup>
import { defineProps, inject, computed } from "vue"
import { unix2ago, isMobile } from "@/main"
import { ContentLoader } from "vue-content-loader"

const { lang } = inject("lang")
const { isDark } = inject("isDark")

const props = defineProps({
  p: Object,
  t: String,
})

const elapsed = computed(() => unix2ago(props.p.created, lang.value))

function innerText(p) {
  const parser = new DOMParser()
  const doc = parser.parseFromString(p.text, "text/html")
  const tags = ["img", "video", "figcaption", "style"]
  tags.forEach((tag) => {
    const elements = doc.getElementsByTagName(tag)
    while (elements[0]) elements[0].parentNode.removeChild(elements[0])
  })
  return doc.body.innerText
}
</script>

<template>
  <figure v-if="props.p" class="link" style="margin-bottom: 0.8rem">
    <router-link :to="`/p/${p.id + (t ? `?t=` + t : ``)}`" style="text-decoration: none">
      <div class="" style="display: flex">
        <div class="column" :style="p.img ? `width: 85% !important;` : ''">
          <li>
            <span style="text-decoration: underline" v-html="p.title"></span>
            <div
              class="color-subtitle indented"
              :style="`overflow: hidden; display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: ${isMobile() ? 1 : 2};`"
            >
              {{ innerText(p) }}
            </div>
            <div class="indented">
              <div
                class="color-subtitle subscript"
                style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap; width: 100%"
              >
                {{ elapsed }}<span v-if="p.tag.length != 0">&nbsp;·&nbsp;</span>
                <span v-for="t in p.tag" :key="t">#{{ t }}&nbsp;</span>
              </div>
            </div>
          </li>
        </div>
        <div v-if="p.img" class="column" style="width: 15% !important">
          <div
            style="
              margin: auto;
              width: 100%;
              padding-top: 100%;
              position: relative;
              overflow: hidden;
            "
          >
            <img
              :src="p.img"
              style="object-fit: cover; inset: 0; position: absolute; height: 100%; margin: auto"
              onerror="this.onerror=null; this.style.display='none';"
            />
            <!-- <img src="https://via.placeholder.com/300x200.png" style="object-fit: cover; position: absolute; inset: 0; height: 100%;"> -->
          </div>
        </div>
      </div>
    </router-link>
  </figure>
  <ContentLoader
    v-else
    width="700"
    height="98"
    viewBox="0 0 700 98"
    :primaryColor="isDark ? '#AAA' : '#CCC'"
    :secondaryColor="isDark ? '#888' : '#BBB'"
    speed="1"
    style="display: block"
  >
    <circle cx="7" cy="12" r="2.5" />
    <rect x="28" y="2" rx="4" ry="4" :width="120 + 160 * Math.random()" height="19" />
    <rect x="30" y="28" rx="4" ry="4" :width="450 + 50 * Math.random()" height="18" />
    <rect x="30" y="51" rx="4" ry="4" :width="300 + 200 * Math.random()" height="18" />
    <rect x="30" y="75" rx="4" ry="4" :width="120 + 160 * Math.random()" height="16" />
    <rect x="609" y="2" width="88" height="88" />
  </ContentLoader>
</template>
