<script setup>
import { defineProps } from "vue"

const props = defineProps({
  links: {
    type: Array,
    default: () => [],
  },
})
</script>

<template>
  <p v-for="(l, li) in props.links" v-bind:key="li">
    🔗 <a :href="l.link" target="_blank" v-html="l.title"></a>
  </p>
</template>
