<script setup>
import axios from "axios"
import { unix2ISO } from "@/main"
import Page from "../components/Page.vue"
import { usePostsStore } from "@/stores/posts"
import { onMounted, onUpdated, ref, defineProps, inject } from "vue"
import { useRoute } from "vue-router"

const { lang } = inject("lang")

const route = useRoute()

const postsStore = usePostsStore()

const props = defineProps({
  id: String,
})

const post = ref(null)
const prevTitle = ref("")
const nextTitle = ref("")

function getPost() {
  const id = parseInt(props.id)

  post.value = postsStore.posts.find((p) => p.id == id)
  if (!post.value) {
    post.value = {
      title: "🤦🏻‍♂️",
      text: "존재하지 않는 게시물이거나 오류가 발생했습니다.",
    }
  }
  if (post.value.text) {
    // self.post.compiledText = Vue.compile(self.post.text);
  } else {
    post.value.text = "아직 작성된 내용이 없습니다."
  }

  const extractor = document.createElement("span")
  extractor.innerHTML = post.value.title
  document.title = extractor.textContent

  prevTitle.value = postsStore.posts.find((p) => p.id == id - 1)?.title
  nextTitle.value = postsStore.posts.find((p) => p.id == id + 1)?.title
}

onMounted(() => {
  postsStore.fetchPosts().then(getPost)
})

onUpdated(() => {
  var url
  var scrollEl
  if (location.href.match(/#[a-zA-Z0-9_]+$/)) {
    const ex = /(.+)(#[a-zA-Z0-9_]+)$/.exec(location.href)
    url = ex[1]
    scrollEl = ex[2]
  } else url = location.href

  const as = document.querySelectorAll("#texttt a")

  for (let a of as) {
    // if href of a starts with href and followed by alphanumeric characters
    if (a.href.startsWith(url) && a.href.replace(url, "").match(/^#[a-zA-Z0-9_]+$/)) {
      a.addEventListener("click", function (e) {
        e.preventDefault()
        document.querySelector(a.href.replace(url, "")).scrollIntoView({
          behavior: "smooth",
        })
      })
    }
  }

  if (scrollEl) {
    document.querySelector(scrollEl).scrollIntoView({
      behavior: "smooth",
    })
  }

  var scripts = document.getElementById("texttt").getElementsByTagName("script")
  const fetchScripts = []

  for (let script of scripts) {
    if (script.src) {
      // Fetch all external scripts in parallel
      fetchScripts.push(
        axios
          .get(script.src)
          .then((response) => response.data)
          .catch((error) => {
            console.error("Error loading the script:", error)
            return "" // Return empty string in case of error to not break the execution chain
          }),
      )
    } else fetchScripts.push(Promise.resolve(script.innerHTML)) // Inline scripts can be pushed as resolved promises
  }

  // Execute all scripts in order after all have been fetched
  Promise.all(fetchScripts).then((scriptContents) => {
    for (let scriptContent of scriptContents) eval(scriptContent)
  })
})
</script>

<template>
  <Page
    v-if="post != null"
    :supertitle="post.supertitle"
    :title="post.title"
    :icon="post.icon"
    :subtitle="post.subtitle"
    :created="unix2ISO(post.created).slice(0, 17)"
    :modified="unix2ISO(post.modified).slice(0, 17)"
    :script="post.script"
    :narrow="true"
  >
    <br />
    {{ postsStore.testString }}

    <div id="texttt" style="text-align: justify">
      <div v-html="post.text"></div>
      <!-- <v-runtime-template :template="post.text"></v-runtime-template> -->
    </div>

    <br />
    <p>
      <span v-for="t in post.tag" :key="t">
        <router-link :to="`/p?t=${t}`"> #{{ t }} </router-link>
        &nbsp;
      </span>
    </p>

    <br />
    <p v-if="lang === 'ko'">
      제 <router-link to="/newsletter">뉴스레터</router-link>를 구독하시면 새 글이 올라왔을 때
      이메일을 받아보실 수 있습니다.
    </p>
    <p v-else>
      Subscribe to my <router-link to="/newsletter">newsletter</router-link> to receive an email
      when a new post is published.
    </p>

    <br />
    <p>
      <span v-if="nextTitle"
        >{{ { ko: "다음글", en: "next" }[lang] }}:
        <router-link :to="`/p/${post.id + 1}`" v-html="nextTitle"
      /></span>
    </p>
    <p>
      <span v-if="prevTitle"
        >{{ { ko: "이전글", en: "previous" }[lang] }}:
        <router-link :to="`/p/${post.id - 1}`" v-html="prevTitle"
      /></span>
    </p>

    <br />
    <p>
      <router-link :to="`/p${route.query.t ? `?t=` + route.query.t : ``}`">
        {{ { ko: "목록으로", en: "back to list" }[lang] }}
      </router-link>
    </p>
  </Page>
</template>

<style scoped>
#texttt >>> p {
  text-indent: 0.5rem;
}
</style>
