<script setup>
// import { inject } from 'vue';
// import Page from '../components/Page.vue';

document.title = "Gunchelin Guide"

// const { lang } = inject('lang');
</script>

<template>
  <Page icon="🍔" title="Gunchelin Guide">
    <!-- <p>{{ { ko: '커밍쑨', en: 'coming soon' }[lang] }}</p> -->

    <!-- 누군가 가려 한다면 말리고 싶다
  재방문 의사 없음


  Downtowner
  Brooklyn The Burger Joint
  Fullinamite
  +82 Burger
  Ollyda Burger
  Frank Burger


  Burger Joint New York
  Five Guys Burgers and Fries
  Shake Shack

  Bullseye Burgers N' Fries (https://goo.gl/maps/S9SM6rwaERcxUg658)

  Burger King
  Mom's Touch
  McDonald's
  -->
  </Page>
</template>
