<script setup>
import { onMounted, onUnmounted, ref } from "vue"
import MenuBar from "../components/MenuBar.vue"
import Copyright from "../components/Copyright.vue"

// const { lang } = inject('lang');

const pickFrom = (arr) => arr[Math.floor(Math.random() * arr.length)]

const memojisCount = 56
const getRandomMemojiUrl = () =>
  `https://images.gunh.ee/newsletter/memojis/${Math.floor(Math.random() * memojisCount)}.png`

const numberOfSubscribersToRender = 1000

const subscribers = ref([])
for (let i = 0; i < numberOfSubscribersToRender; i++) {
  const deviceType = pickFrom(["phone", "laptop"])
  const deviceBrand = pickFrom(["apple", "samsung"])
  let deviceColor
  if (deviceBrand === "apple") {
    if (deviceType === "phone")
      deviceColor = pickFrom([
        "#ccc",
        "#ccc",
        "#ccc",
        "#ccc",
        "#999",
        "#999",
        "#999",
        "#999",
        "#10505B",
        "#E09019",
        "#E96740",
        "#B62D31",
        "#363B71",
        "#25476D",
      ])
    else
      deviceColor = pickFrom([
        "#ccc",
        "#ccc",
        "#ccc",
        "#ccc",
        "#999",
        "#999",
        "#999",
        "#999",
        "#10505B",
        "#E09019",
        "#E96740",
        "#B62D31",
        "#363B71",
        "#25476D",
      ])
  } else {
    if (deviceType === "phone")
      deviceColor = pickFrom([
        "#ccc",
        "#ccc",
        "#ccc",
        "#ccc",
        "#999",
        "#999",
        "#999",
        "#999",
        "#10505B",
        "#E09019",
        "#E96740",
        "#B62D31",
        "#363B71",
        "#25476D",
      ])
    else
      deviceColor = pickFrom([
        "#ccc",
        "#ccc",
        "#ccc",
        "#ccc",
        "#999",
        "#999",
        "#999",
        "#999",
        "#10505B",
        "#E09019",
        "#E96740",
        "#B62D31",
        "#363B71",
        "#25476D",
      ])
  }
  subscribers.value.push({
    id: i,
    memojiUrl: getRandomMemojiUrl(),
    rotation: -15 + Math.random() * 20,
    r_: 0.98 * Math.random(),
    theta: Math.random() * 2 * Math.PI,
    device: {
      type: deviceType,
      brand: deviceBrand,
      color: deviceColor,
    },
  })
}

const bgMetadata = { width: 2800, height: 2000 }

function calculateSubscriberPositions() {
  const windowWiderThanImage =
    window.innerWidth / bgMetadata.width > window.innerHeight / bgMetadata.height
  // const bgWidth = windowWiderThanImage ? window.innerWidth : window.innerHeight * bgMetadata.width / bgMetadata.height;
  const bgHeight =
    windowWiderThanImage ?
      (window.innerWidth * bgMetadata.height) / bgMetadata.width
    : window.innerHeight

  const earthX = bgHeight
  const earthY = (bgHeight * 3850) / 2000
  const earthR = (bgHeight * 2660) / 2000

  const subscribersNew = subscribers.value

  for (let i = 0; i < numberOfSubscribersToRender; i++) {
    const subscriber = subscribersNew[i]
    subscriber.top = earthY + earthR * subscriber.r_ * Math.sin(subscriber.theta)
    subscriber.left = earthX + earthR * subscriber.r_ * Math.cos(subscriber.theta)
    const margin = 30
    subscriber.visible =
      subscriber.top + margin < window.innerHeight && subscriber.left + margin < window.innerWidth
  }

  subscribersNew.sort((a, b) => a.top - b.top)

  subscribers.value = subscribersNew
}

onMounted(() => {
  calculateSubscriberPositions()
  window.addEventListener("resize", calculateSubscriberPositions)
})

onUnmounted(() => {
  window.removeEventListener("resize", calculateSubscriberPositions)
})
</script>

<template>
  <div id="container">
    <MenuBar />

    <template v-for="subscriber in subscribers" v-bind:key="subscriber.id">
      <div
        v-if="subscriber.visible"
        :style="{ top: subscriber.top + 'px', left: subscriber.left + 'px' }"
        class="subscriber"
      >
        <img
          :src="subscriber.memojiUrl"
          alt=""
          srcset=""
          :style="{
            width: '40px',
            height: '40px',
            rotate: subscriber.rotation + 'deg',
          }"
        />
        <div
          :style="{
            position: 'absolute',
            bottom: '-3px',
            left: '53%',
            transform: 'translate(-50%, -50%)',
            width: subscriber.device.type === 'phone' ? '10px' : '30px',
            height: subscriber.device.type === 'phone' ? '18px' : '18px',
            backgroundColor: subscriber.device.color,
            borderRadius: '2px',
            display: 'flex',
            flexDirection: subscriber.device.type === 'laptop' ? 'row' : 'column',
            alignItems: 'center',
            justifyContent:
              subscriber.device.brand === 'apple' ? 'center'
              : subscriber.device.type === 'laptop' ? 'start'
              : 'end',
          }"
        >
          <div
            style="
              position: absolute;
              width: 100%;
              height: 100%;
              background: linear-gradient(
                to bottom,
                rgba(0, 0, 0, 0) 0%,
                rgba(0, 0, 0, 0.1) 50%,
                rgba(0, 0, 0, 0.3) 100%
              );
            "
          >
            <div v-if="subscriber.device.type === 'phone'" style="padding: 1.5px 0 0 1px">
              <div
                v-for="i in 2"
                v-bind:key="i"
                :style="{
                  width: '1.5px',
                  height: '1.5px',
                  marginBottom: '0.5px',
                  borderRadius: '9999px',
                  backgroundColor: 'rgba(0,0,0,1)',
                }"
              ></div>
            </div>
          </div>
          <div
            v-if="subscriber.device.brand === 'samsung'"
            :style="{
              fontSize: subscriber.device.type === 'laptop' ? '1.5px' : '1px',
              margin: subscriber.device.type === 'laptop' ? '0 0 0 2px' : '0 0 1px 0',
            }"
          >
            SAMSUNG
          </div>
          <!-- <div v-else :style="{ width: '5px', height: '5px', borderRadius: '9999px', backgroundColor: 'rgba(255,255,255,0.5)' }"></div> -->
          <img
            v-else
            src="https://images.gunh.ee/newsletter/apple.png"
            :style="{
              width: subscriber.device.type === 'laptop' ? '5px' : '2px',
            }"
          />
        </div>
      </div>
    </template>

    <slot></slot>

    <div id="acknowledgement">
      Design inspired by
      <a href="https://youtu.be/GEZhD3J89ZE" target="_blank">WWDC2020</a>.<br />
      Background image by
      <a
        href="https://www.freepik.com/free-photo/galaxy-night-panorama_13140058.htm"
        target="_blank"
        >Freepik</a
      >.
    </div>
    <Copyright />
  </div>
</template>

<style>
#container {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url("https://images.gunh.ee/newsletter/earth.jpg");
  background-size: cover;
  text-align: center;
  padding-top: 100px;
  color: white;
}

/* #earth {
    position: absolute;
    bottom: 0px; right: 0px;
    width: 100%; height: 100%;
    background-image: url('../assets/newsletter-earth.jpg');
    background-size: cover;
} */

.subscriber {
  position: absolute;
  transform: translate(-50%, -50%);
  font-size: 20px;
}

#email-input {
  border: none;
  border-bottom: 1px solid white; /* Adjust color and thickness as needed */
  border-radius: 0;
  outline: none; /* Remove default focus outline */
  background-color: transparent; /* Remove background color */
  color: inherit;
  font-size: 16px;
  text-align: center;
  width: 200px;
  /* height: 100px; */
  box-sizing: border-box;
  padding: 0.125rem;
}
input:focus::placeholder {
  color: transparent;
}

.newsletter-message-container {
  margin: 20px 0;
}

button {
  background-color: transparent;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 14px;
  padding: 4px 10px;
}

.newsletter-title {
  font-size: 1.5rem;
  font-weight: bold;
}

.newsletter-warning {
  color: red;
  font-size: 90%;
  margin-top: 1rem;
}

button:hover {
  color: lightgray;
}

#acknowledgement {
  opacity: 0.5;
  font-size: 80%;
}
</style>
