<template>
  <router-view :key="$route.fullPath" />
</template>

<script setup>
import { ref, watch, provide } from "vue"

/*

Node 최신 버전에서 serve 에러 날 때: export NODE_OPTIONS=--openssl-legacy-provider
(https://github.com/vuejs/vue-cli/issues/6770)

++ TODO ++++++++++++++++++++++++++++++++++++++++++++++++++

- 존재하는 게시물 숨기기
- 비어있는 id 게시물 건너뛰어서 이전글/다음글 보이기
- 최근 업데이트 게시물 표시 (어깨점 분홍 동그라미) 구현 방식 개선

++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

*/

const lang = ref("ko")
watch(lang, (newVal) => {
  document.documentElement.lang = newVal
  document.documentElement.dir = newVal === "ar" ? "rtl" : "ltr"
})
const setLang = (_) => {
  lang.value = _
}
const toggleLang = () => {
  lang.value = lang.value === "ko" ? "en" : "ko"
}
provide("lang", { lang, setLang, toggleLang })

const isDark = ref(window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches)
window.matchMedia("(prefers-color-scheme: dark)").addEventListener("change", (event) => {
  isDark.value = event.matches
})
watch(isDark, (newVal) => {
  document.documentElement.classList.toggle("dark", newVal)
  //   document.body.classList.toggle('light', !newVal);
})
const toggleIsDark = () => {
  isDark.value = !isDark.value
}
provide("isDark", { isDark, toggleIsDark })
</script>
