<script setup>
import { inject } from "vue"

const { lang } = inject("lang")

// const props = defineProps({
//   marginTop: null
// });
</script>

<template>
  <div
    :style="{
      paddingTop: '3rem',
      paddingBottom: '2rem',
      textAlign: 'center',
      opacity: '0.5',
    }"
  >
    © 2024 {{ { ko: "이건희", en: "Gunhee Aaron Yi" }[lang] }}
  </div>
</template>
