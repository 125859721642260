<script setup>
document.title = "뉴스레터"

import { useRoute } from "vue-router"
import Newsletter_Template from "../components/Newsletter_Template.vue"

const route = useRoute()

const email = route.query.email
</script>

<template>
  <Newsletter_Template>
    <div>뉴스레터</div>
    <div class="newsletter-title">구독 해지 완료</div>

    <div class="newsletter-message-container">
      <p>{{ email }}의<br />뉴스레터 구독 해지를 완료했습니다.</p>
    </div>

    <div class="newsletter-message-container">
      <router-link to="/newsletter">다시 구독하기</router-link>
    </div>
  </Newsletter_Template>
</template>

<style></style>
