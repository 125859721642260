<template>
  <Page title="전송">
    <br />

    <div class="column-list" style="height: 60vh">
      <div class="column column-2" style="overflow: scroll">
        <h3>{{ subject }}</h3>
        <br />
        <div v-html="body"></div>
      </div>
      <div class="column column-2" style="display: flex; flex-direction: column">
        <input v-model="subject" placeholder="제목" style="width: 100%" />
        <textarea v-model="body" style="width: 100%; flex: 1; margin-top: 1rem"></textarea>
        <div style="margin-top: 1rem">
          게시물 ID&nbsp;
          <input type="text" v-model="previewPostId" style="width: 40px" />&nbsp;를&nbsp;
          <input type="text" v-model="previewLengthLimit" style="width: 40px" />&nbsp;자까지&nbsp;
          <button @click="copyPostPreview" style="">미리보기 복사</button>
        </div>
      </div>
    </div>
    <div
      style="
        margin-top: 1rem;
        display: flex;
        flex-direction: row;
        justify-content: end;
        align-items: center;
      "
    >
      <div>
        <input type="password" v-model="password" placeholder="password" style="width: 120px" />
        <button @click="send" style="">전송</button>
      </div>
    </div>
  </Page>
</template>

<script setup>
import { ref } from "vue"
import Page from "../components/Page.vue"
import axios from "axios"
import { usePostsStore } from "@/stores/posts"

document.title = "뉴스레터 전송"

const postsStore = usePostsStore()

const subject = ref("[주건희받건희#]")
const body = ref("")
const previewPostId = ref("")
const previewLengthLimit = ref(500)
const password = ref("")

const send = async () => {
  if (!confirm("전송하시겠습니까?")) {
    return
  }
  try {
    await axios.post("/api/newsletter", {
      subject: subject.value,
      body: body.value,
      password: password.value,
    })
  } catch (e) {
    if (e.response.status === 401) alert("인증에 실패했습니다.")
    else alert("전송 시작에 실패했습니다.")
    return
  }
  alert("전송이 시작되었습니다.")
}

const copyPostPreview = async () => {
  const postIdInInt = parseInt(previewPostId.value)

  await postsStore.fetchPosts()
  const post = postsStore.posts.find((p) => p.id == postIdInInt)
  if (!post) {
    alert("게시물을 찾을 수 없습니다.")
    return
  }
  if (!post.text) {
    alert("게시물 내용이 없습니다.")
    return
  }

  let previewHeader = ""

  if (post.supertitle) previewHeader += `<div>${post.supertitle}</div>`
  if (post.title)
    previewHeader += `<div style="font-size: 1.5rem; font-weight: bold">${post.title}</div>`
  if (post.subtitle) previewHeader += `<div>${post.subtitle}</div>`

  previewHeader = `
    <div>
      ${previewHeader}
    </div>
    <br/>
  `

  const extractor = document.createElement("div")
  extractor.innerHTML = post.text

  // Modify anchor tags with relative hrefs to prepend domain
  const anchors = extractor.getElementsByTagName("a")
  for (let anchor of anchors) {
    if (anchor.getAttribute("href") && anchor.getAttribute("href").startsWith("/")) {
      anchor.setAttribute("href", `https://gunh.ee${anchor.getAttribute("href")}`)
    }
  }

  let preview = ""
  let previewTextLength = 0
  let previewHtmlLength = 0
  for (const child of extractor.children) {
    if (
      [
        "STYLE",
        "SCRIPT",
        "IFRAME",
        "VIDEO",
        "AUDIO",
        "CANVAS",
        "SVG",
        "IMG",
        "OBJECT",
        "EMBED",
        "APPLET",
        "FRAME",
        "FRAMESET",
        "NOEMBED",
        "NOSCRIPT",
        "PLAINTEXT",
        "SCRIPT",
        "TEMPLATE",
        "TEXTAREA",
        "XMP",
      ].includes(child.tagName)
    ) {
      continue
    }
    preview += child.outerHTML
    previewTextLength += child.textContent.length
    previewHtmlLength += child.outerHTML.length
    if (
      previewTextLength > previewLengthLimit.value ||
      previewHtmlLength > previewLengthLimit.value * 2
    ) {
      break
    }
  }

  preview += `<p>…</p><a href="https://gunh.ee/p/${post.id}">🔗 계속 읽기: &lt;${post.title}&gt;</a>`

  navigator.clipboard.writeText(previewHeader + preview)
  alert("게시물 미리보기가 복사되었습니다.")
}
</script>

<style>
input,
textarea {
  background-color: #1f1f1f;
  color: #ebebeb;
  border: none;
  padding: 0.5rem;
  border-radius: 0.25rem;
}
</style>
