<script setup>
document.title = "Workouts"

import axios from "axios"
import { onMounted, ref, inject } from "vue"
import Page from "../components/Page.vue"
import BodyWeightsChart from "../components/BodyWeightsChart.vue"

const { lang } = inject("lang")
const { isDark } = inject("isDark")

function commafy(num) {
  var str = num.toFixed(1).split(".")
  if (str[0].length >= 5) {
    str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, "$1,")
  }
  if (str[1] && str[1].length >= 5) {
    str[1] = str[1].replace(/(\d{3})/g, "$1 ")
  }
  return str.join(".")
}

const bodyWeights = ref([])
const inbodies = [
  // { date: [2006, 6, 24], height: 122.0, weight: 22.2 },
  // { date: [2012, 6, 2], height: 152.1, weight: 42.4 },
  // { date: [2013, 4, 15], height: 161.8, weight: 53.7 },
  // { date: [2014, 4, 28], height: 170.5, weight: 62.0 },
  // { date: [2015, 10, 6], height: 175.3, weight: 66.2 },
  {
    date: [2020, 12, 2],
    weight: 70.8,
    muscle: 32.6,
    fat: 12.8,
    water: 0,
    protein: 0,
    mineral: 0,
    calories: 0,
  },
  // { date: [2022, 12, 8], weight: 68.7, muscle: 33.1, fat: 10.0, water: 0, protein: 0, mineral: 0, calories: 0 },
  {
    date: [2022, 12, 14],
    weight: 68.2,
    muscle: 32.9,
    fat: 9.8,
    water: 42.8,
    protein: 11.5,
    mineral: 4.08,
    calories: 1632,
  },
  {
    date: [2023, 2, 1],
    weight: 70.5,
    muscle: 33.9,
    fat: 10.3,
    water: 44.1,
    protein: 11.9,
    mineral: 4.24,
    calories: 1670,
  },
  {
    date: [2023, 3, 1],
    weight: 71.8,
    muscle: 34.5,
    fat: 10.7,
    water: 44.7,
    protein: 12.1,
    mineral: 4.31,
    calories: 1690,
  },
  {
    date: [2023, 4, 16],
    weight: 71.6,
    muscle: 35.5,
    fat: 9.2,
    water: 45.6,
    protein: 12.5,
    mineral: 4.31,
    calories: 1717,
  },
].reverse()
const workouts = ref([])
const volumeAgg = ref(0)

onMounted(() => {
  async function fetchData() {
    const workouts_legacy_data = (await axios.get("/workouts_legacy.json")).data.workouts

    let workouts_legacy = []
    let bodyWeights_legacy = []

    workouts_legacy_data.forEach((workout) => {
      const date = new Date(workout.date[0], workout.date[1] - 1, workout.date[2])

      if (!workout.parts.length) {
        bodyWeights_legacy.push({ date, bodyWeight: workout.weight })
        return
      }

      workouts_legacy.push({
        date,
        title: workout.parts.join("·"),
        notes: workout.comment,
        types: workout.types.map((type) => {
          return {
            name: type.name,
            sets: type.sets.map((set) => {
              return {
                weight: set.weight,
                reps: set.reps,
                distance: set.distance,
                elevation: set.elevation,
                time: set.time,
                notes: set.comment,
              }
            }),
          }
        }),
      })
    })

    const sets = (await axios.get("/strong.csv")).data
      .split("\n")
      .slice(1, -1)
      .map((e) => e.split(","))
      .map((e) => {
        let _e = {}
        _e.date = new Date(e[0])
        _e.title = eval(e[1])
        _e.duration = e[2]
        _e.name = eval(e[3])
        _e.setOrder = eval(e[4]) || undefined
        _e.weight = eval(e[5]) || undefined
        _e.reps = eval(e[6]) || undefined
        _e.distance = eval(e[7]) || undefined
        _e.time = eval(e[8]) || undefined
        _e.notes = eval(e[9]) || undefined
        _e.workoutNotes = eval(e[10]) || undefined
        _e.RPE = eval(e[11]) || undefined

        return _e
      })

    const types = sets.reduce((typesAccumulator, set) => {
      const i = typesAccumulator.findIndex((type) => {
        return (
          type.date.getTime() == set.date.getTime() &&
          type.title == set.title &&
          type.name == set.name
        )
      })

      let _set = {
        setOrder: set.setOrder,
        weight: set.weight,
        reps: set.reps,
        distance: set.distance,
        time: set.time,
        notes: set.notes,
        workoutNotes: set.workoutNotes,
        RPE: set.RPE,
      }

      if (i >= 0) {
        typesAccumulator[i].sets.push(_set)
      } else {
        typesAccumulator.push({
          date: set.date,
          title: set.title,
          name: set.name,
          sets: [_set],
        })
      }

      return typesAccumulator
    }, [])

    const workouts_strong = types.reduce((workoutsAccumulator, type) => {
      const i = workoutsAccumulator.findIndex((workout) => {
        return workout.date.getTime() == type.date.getTime() && workout.title == type.title
      })

      const _type = {
        name: type.name,
        sets: type.sets,
      }

      if (i >= 0) {
        workoutsAccumulator[i].types.push(_type)
      } else {
        workoutsAccumulator.push({
          date: type.date,
          title: type.title,
          types: [_type],
        })
      }

      return workoutsAccumulator
    }, [])

    return {
      workouts: [...workouts_legacy, ...workouts_strong],
      bodyWeights: bodyWeights_legacy,
    }
  }

  fetchData().then((data) => {
    bodyWeights.value = data.bodyWeights
    workouts.value = data.workouts.sort((a, b) => b.date - a.date)
    for (let workout of workouts.value) {
      for (let type of workout.types) {
        for (let set of type.sets) {
          if (set.weight) {
            volumeAgg.value += set.weight * set.reps
          }
        }
      }
    }
    volumeAgg.value = commafy(volumeAgg.value)
  })
})
</script>

<template>
  <Page icon="dumbbell.png" title="Workouts">
    <!-- https://musclewiki.com/ -->
    <!-- https://www.dreamstime.com/stock-images-dumbell-weights-image12559044 -->

    <p v-if="lang === 'ko'">
      <!-- 헬스 입문 2022.12.09.<br> -->
      일지 작성 2023.01.04. ~
    </p>
    <p v-else>Journaling since Jan 4th 2023.</p>

    <p v-if="lang === 'ko'">누적 (유무산소 도합) {{ workouts.length }}회 / {{ volumeAgg }}kg</p>
    <p v-else>
      {{ workouts.length }} workouts in total (both aerobic & anaerobic) / {{ volumeAgg }}kg
    </p>

    <BodyWeightsChart :bodyWeights="bodyWeights" />

    <h3>InBody</h3>
    <p v-if="lang === 'ko'">신장 179cm<br />* 체중 = 골격근량 + 체지방량 + ⍺ (기초대사량)</p>
    <p v-else>height 179cm<br />* bodyweight = muscle mass + fat mass + ⍺ (basal metabolic rate)</p>
    <ul style="padding-inline-start: 0px; display: flex; flex-direction: column; gap: 5px">
      <div
        style="display: flex; flex-direction: row; align-items: center"
        v-for="e in inbodies"
        v-bind:key="e.date"
        :id="`i${e.date[0]}-${String(e.date[1]).padStart(2, '0')}-${String(e.date[2]).padStart(
          2,
          '0',
        )}`"
      >
        <div
          :style="{
            width: '8px',
            height: '8px',
            backgroundColor: isDark ? '#EBEBEB' : 'black',
            marginLeft: '10px',
            marginRight: '7px',
          }"
        ></div>
        <div>
          <span>{{
            lang === "ko" ?
              `${e.date[0]}년 ${String(e.date[1])}월 ${String(e.date[2])}일`
            : `${e.date[0]}-${String(e.date[1]).padStart(2, "0")}-${String(e.date[2]).padStart(2, "0")}`
          }}</span
          >,&nbsp;{{ e.weight }}kg = {{ e.muscle }}kg + {{ e.fat }}kg + ⍺
          <span v-if="e.calories">({{ e.calories }}kcal)</span>
        </div>
      </div>
    </ul>

    <h3>일별</h3>
    <ul class="toggle" style="display: flex; flex-direction: column; gap: 5px">
      <template v-for="(workout, i) in workouts" v-bind:key="i">
        <li
          v-if="workout.title"
          :id="`d${workout.date.getFullYear()}-${String(workout.date.getMonth() + 1).padStart(
            2,
            '0',
          )}-${String(workout.date.getDate()).padStart(2, '0')}`"
        >
          <details :open="i < 3">
            <summary>
              {{
                lang === "ko" ?
                  `${workout.date.getFullYear()}년 ${String(workout.date.getMonth() + 1)}월 ${String(workout.date.getDate())}일`
                : `${workout.date.getFullYear()}-${String(workout.date.getMonth() + 1).padStart(2, "0")}-${String(workout.date.getDate()).padStart(2, "0")}`
              }}<template v-if="workout.title"
                >,&nbsp;<b>{{ workout.title }}</b></template
              >
              <span class="color-subtitle subscript">
                <!-- <span v-if="workout.weight"> {{ ` ${workout.weight.toFixed(1)}` }}kg </span> -->
                <template v-if="workout.title">
                  {{ " " }}
                  <!-- <span v-if="c.with.length <= 4">
                    <span v-for="p in c.with" v-bind:key="p" :title="p">
                      <svg viewBox="0 0 512 512" style="width: 10px; height: 10px; margin-right: 2px;">
                        <g fill="#999999">
                          <path d="M256,256c70.5,0,128-57.5,128-128S326.5,0,256,0S128,57.5,128,128C128.9,198.5,185.5,256,256,256z M256,320C170.7,320,0.9,362.7,0.9,448v64h510.1v-64C511.1,362.7,341.3,320,256,320z"/>
                        </g>
                      </svg>
                    </span>
                  </span>
                  <span v-else>
                    <svg viewBox="0 0 512 512" style="width: 10px; height: 10px; margin-right: 2px;">
                      <g fill="#999999">
                        <path d="M256,256c70.5,0,128-57.5,128-128S326.5,0,256,0S128,57.5,128,128C128.9,198.5,185.5,256,256,256z M256,320C170.7,320,0.9,362.7,0.9,448v64h510.1v-64C511.1,362.7,341.3,320,256,320z"/>
                      </g>
                    </svg>{{c.with.length}}
                  </span> -->
                </template>
              </span>
            </summary>
            <template v-if="workout.types.length">
              <p
                style="margin-top: 0.1em; margin-bottom: 0.1em"
                v-for="t in workout.types"
                v-bind:key="t.name"
              >
                <!-- 이름 -->
                <b v-if="t.name">{{ t.name }}</b>
                <!-- 거리 -->

                |
                <!-- 세트 -->
                <template v-for="(s, i) in t.sets" v-bind:key="i">
                  <template v-if="s.weight">{{ `${s.weight}kg×${s.reps}` }}</template>
                  <template v-else-if="s.reps"
                    >{{ s.reps }}{{ lang === "ko" ? "회" : "reps" }}</template
                  >
                  <template v-else-if="s.distance && s.time">
                    <template v-if="s.distance">
                      <template v-if="s.elevation">→</template>
                      <template v-if="s.distance < 1000">{{ `${s.distance}m` }}</template>
                      <template v-if="s.distance % 1000 == 0">{{
                        `${s.distance / 1000}km`
                      }}</template>
                      <template v-else>{{ `${(s.distance / 1000).toFixed(1)}km` }}</template>
                      <template v-if="s.elevation">&nbsp;↑{{ s.elevation }}m</template>
                    </template>
                    @ <template v-if="s.time[0]">{{ `${s.time[0]}'` }}</template
                    >{{
                      `${String(s.time[1]).padStart(2, "0")}'${String(s.time[2]).padStart(
                        2,
                        "0",
                      )}''`
                    }}
                  </template>
                  <template v-if="i < t.sets.length - 1"> - </template>
                </template>
                <!-- 시간 -->
                <!-- <template v-if="t.time">
                  
                </template> -->
                <!-- 메모 -->
                <span v-if="t.comment"
                  >&nbsp;
                  <span style="font-size: 60%">*{{ t.comment }}</span>
                </span>
              </p>
            </template>

            <template v-if="workout.notes" style="text-align: justify">
              <p v-for="(e, i) in workout.notes.split('\n')" v-bind:key="i">
                {{ e }}
              </p>
            </template>
          </details>
        </li>
        <div
          v-else-if="workout.weight"
          :id="`d${workout.date[0]}-${String(workout.date[1]).padStart(
            2,
            '0',
          )}-${String(workout.date[2]).padStart(2, '0')}`"
          style="display: flex; flex-direction: row; align-items: center"
          class="color-subtitle"
        >
          <div
            style="
              width: 8px;
              height: 8px;
              background-color: #999999;
              margin-left: 11px;
              margin-right: 6px;
            "
          ></div>
          <div>
            <!-- ◾️■◼︎■▪️◼️⬛️◼ -->
            <!-- ▪️&nbsp; -->
            {{
              lang === "ko" ?
                `${workout.date[0]}년 ${String(workout.date[1])}월 ${String(workout.date[2])}일`
              : `${workout.date[0]}-${String(workout.date[1]).padStart(2, "0")}-${String(workout.date[2]).padStart(2, "0")}`
            }}
            <span class="color-subtitle subscript"> {{ ` ${workout.weight.toFixed(1)}` }}kg </span>
          </div>
        </div>
      </template>
    </ul>
  </Page>
</template>

<style scoped></style>
