<script setup>
// TODO: translate destinations to English

document.title = "체크포인트"

import { ref, onMounted, inject } from "vue"
import { GoogleMap, Marker } from "vue3-google-map"
import Page from "../components/Page.vue"
import { ckpts, otherCkpts } from "@/main"

const { lang } = inject("lang")
const { isDark } = inject("isDark")

const mapStyles = [
  {
    elementType: "geometry",
    stylers: [
      {
        color: "#212121",
      },
    ],
  },
  {
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#212121",
      },
    ],
  },
  {
    featureType: "administrative",
    elementType: "geometry",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "administrative.country",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
  {
    featureType: "administrative.land_parcel",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "administrative.locality",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#bdbdbd",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [
      {
        color: "#181818",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#1b1b1b",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#2c2c2c",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#8a8a8a",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "geometry",
    stylers: [
      {
        color: "#373737",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [
      {
        color: "#3c3c3c",
      },
    ],
  },
  {
    featureType: "road.highway.controlled_access",
    elementType: "geometry",
    stylers: [
      {
        color: "#4e4e4e",
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161",
      },
    ],
  },
  {
    featureType: "transit",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        color: "#000000",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#3d3d3d",
      },
    ],
  },
]

const locations = ref([])
const ckpts_load = ref([])

function getCkpts() {
  ckpts_load.value = ckpts
  ckpts_load.value.sort((a, b) => b.end.localeCompare(a.end))
  locations.value = []
  for (var ckpt of ckpts_load.value) {
    ckpt.start = ckpt.start.replace(/-/gi, ".") + "."
    ckpt.end = ckpt.end.replace(/-/gi, ".") + "."
    if (ckpt.location) for (var l of ckpt.location) locations.value.push(l)
  }
  for (l of otherCkpts) locations.value.push(l)
}

onMounted(getCkpts)
</script>

<template>
  <Page icon="📌" :title="lang == 'ko' ? '체크포인트' : 'Checkpoints'">
    <br />
    <GoogleMap
      api-key="AIzaSyB-5S0xir9l9mKxOx4aIu-2YVy7BMzdG5o"
      style="width: 100%; height: 50vh"
      :center="{ lat: 36.275, lng: 127.769 }"
      :zoom="2"
      :language="lang"
      :styles="isDark ? mapStyles : null"
    >
      <Marker v-for="loc in locations" v-bind:key="loc.lat" :options="{ position: loc }" />
    </GoogleMap>
    <div class="indented">
      <p>
        🇰🇷
        {{ lang === "ko" ? "고양 / 서울 / 대전 / 부산" : "Goyang / Seoul / Daejeon / Busan" }}
        + α (1999.04.05. - )
      </p>
      <p v-for="p in ckpts_load" :key="p.start">
        {{ p.country }} {{ lang === "ko" ? p.cityKr : p.cityEng }} ({{ p.start }} - {{ p.end }})
      </p>
      <p>
        🇸🇬 🇮🇩
        {{ lang === "ko" ? "싱가폴 / 바탐" : "Singapore / Batam" }} (2004.07.? 08.?)
      </p>
      <p>🇹🇭 {{ lang === "ko" ? "방콕" : "Bangkok" }} (2003.12.?)</p>
      <p>🇺🇸 {{ lang === "ko" ? "괌" : "Guam" }} (2001.11.? 12.?)</p>
    </div>
  </Page>
</template>
