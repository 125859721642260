<script setup>
document.title = "뉴스레터"

import { ref, onMounted } from "vue"
import { useRoute } from "vue-router"
import axios from "axios"
import Newsletter_Template from "../components/Newsletter_Template.vue"

const route = useRoute()

const subscribersCount = ref("n")

const email = ref("")
const emailInvalidWarningShown = ref(false)
const hideEmailInvalidWarning = () => {
  emailInvalidWarningShown.value = false
}

const sendingEmail = ref(false)

onMounted(() => {
  axios
    .get("/api/subscribers/count")
    .then((response) => {
      subscribersCount.value = response.data.count
    })
    .catch((error) => {
      console.error(error)
    })
})

function subscribe() {
  if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/i.test(email.value)) {
    emailInvalidWarningShown.value = true
    return
  }
  sendingEmail.value = true
  axios
    .post(`/api/subscribers/${email.value}`)
    .then(() => {
      window.location.href = "/newsletter?pendingVerificationFor=" + email.value
    })
    .catch((error) => {
      console.error(error)
    })
}
</script>

<template>
  <Newsletter_Template>
    <h1>Stay connected!</h1>

    <p>gunh.ee의 뉴스레터<br />『주건희받건희』를 구독하고<br />새 글들을 빠르게 받아보세요.</p>

    <div v-if="sendingEmail" class="newsletter-message-container">
      인증 이메일을 발송 중입니다...
    </div>
    <div v-else-if="route.query.pendingVerificationFor" class="newsletter-message-container">
      <i>{{ route.query.pendingVerificationFor }}</i
      >(으)로 이메일을 발송했습니다.<br />
      이메일을 따라서 구독 신청을 완료해주세요.
    </div>
    <div v-else>
      <div class="newsletter-message-container">
        <input
          v-model="email"
          id="email-input"
          type="text"
          placeholder="이메일 주소"
          @click="hideEmailInvalidWarning"
        /><button @click="subscribe">구독하기</button>
        <p v-if="emailInvalidWarningShown" class="newsletter-warning">
          이메일 주소 형식을 확인해주세요.
        </p>
      </div>

      <p>{{ subscribersCount }}명의 구독자가 함께하고 있습니다.</p>
    </div>
  </Newsletter_Template>
</template>
