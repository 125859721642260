<script setup>
import { inject } from "vue"

const { lang, toggleLang } = inject("lang")
const { isDark, toggleIsDark } = inject("isDark")
</script>

<template>
  <div class="back">
    <a :style="{ cursor: 'pointer' }" @click="$router.go(-1)"
      >← {{ { ko: "뒤로", en: "back" }[lang] }}</a
    >
    &nbsp;&nbsp;
    <router-link to="/">
      {{ { ko: "메인", en: "main" }[lang] }}
    </router-link>
  </div>
  <div class="front">
    <a :style="{ cursor: 'pointer' }" @click="toggleLang">{{
      { ko: "English", en: "한국어" }[lang]
    }}</a>
    &nbsp;&nbsp;
    <a :style="{ cursor: 'pointer' }" @click="toggleIsDark">{{
      { true: "☼", false: "☾" }[isDark]
    }}</a>
  </div>
</template>
