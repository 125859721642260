import axios from "axios"

import { defineStore } from "pinia"

export const usePostsStore = defineStore({
  id: "posts",
  state: () => ({
    posts: [],
    playlists: [],
  }),
  actions: {
    async fetchPosts() {
      if (this.posts.length) return

      const response = await axios.get("/api/posts")
      this.posts = response.data.sort((b, a) => a.created - b.created)
    },
    async fetchPlaylists() {
      if (this.playlists.length) return
      // GET request using fetch with async/await
      const response = await axios.get("/api/playlists")
      this.playlists = response.data
    },
  },
})
