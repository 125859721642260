<script setup>
import { defineProps, onMounted, inject } from "vue"

import Copyright from "./Copyright.vue"
import MenuBar from "./MenuBar.vue"

const { lang, toggleLang } = inject("lang")

const props = defineProps({
  title: String,
  icon: String,
  supertitle: String,
  subtitle: String,
  text: String,
  created: String,
  modified: String,
  script: String,
  narrow: Boolean,
  koreanOnly: Boolean,
})

const iconIsEmoji = props.icon && /\p{Extended_Pictographic}/gu.test(props.icon)

function getImgUrl(pic) {
  if (pic.startsWith("http")) return pic
  return require("../assets/" + pic)
}

onMounted(() => {
  eval(props.script)
})
</script>

<template>
  <MenuBar />

  <!-- <img class="page-cover-image" src="@/assets/seoul_cropped.jpeg"/> -->
  <article class="page" :style="narrow ? `max-width: 886px;` : ``">
    <!--원래 886px-->

    <header>
      <div v-if="icon" class="page-header-icon page-header-icon-with-cover">
        <!-- <img class="icon" src="https://via.placeholder.com/1000.png/09f/fff/" /> -->
        <template v-if="iconIsEmoji !== null">
          <span v-if="iconIsEmoji" class="icon">{{ icon }}</span>
          <img v-if="!iconIsEmoji" class="icon" :src="getImgUrl(icon)" />
        </template>
      </div>
      <h1 class="page-title" :style="icon ? `` : `margin-top: 2rem;`">
        <div v-if="supertitle" style="font-size: 1.25rem; margin-bottom: 0.25rem; font-weight: 400">
          {{ supertitle }}
        </div>
        <span v-html="title"></span>
        <div v-if="subtitle" style="font-size: 1.25rem; margin-top: 0.25rem; font-weight: 400">
          {{ subtitle }}
        </div>
        <div v-if="created" style="font-size: 16px; margin-top: 1rem; font-weight: 400">
          {{ { ko: "입력", en: "created:" }[lang] }} {{ created
          }}<span v-if="created != modified"
            >&nbsp;·&nbsp;{{ { ko: "수정", en: "modified:" }[lang] }} {{ modified }}</span
          >
        </div>
      </h1>
    </header>

    <div class="page-body">
      <template v-if="props.koreanOnly && lang !== 'ko'">
        <p>Sorry, this page is offered only in Korean.</p>
        <p>
          <a :style="{ cursor: 'pointer' }" @click="toggleLang">Switch to Korean</a>
        </p>
      </template>
      <slot v-else></slot>
    </div>

    <Copyright />
  </article>
</template>
