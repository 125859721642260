<script setup>
document.title = "뉴스레터"

import { ref, onMounted } from "vue"
import { useRoute } from "vue-router"
import axios from "axios"
import Newsletter_Template from "../components/Newsletter_Template.vue"

const route = useRoute()

const verifyingDone = ref(false)
const verificationResult = ref(null)

const subscribersCount = ref("n")

onMounted(() => {
  axios
    .put(`/api/subscribers/${route.query.email}?action=verify&token=${route.query.token}`)
    .then((res) => {
      verifyingDone.value = true
      verificationResult.value = res.data.message
      subscribersCount.value = res.data.count
    })
    .catch(() => {
      verifyingDone.value = true
    })
})
</script>

<template>
  <Newsletter_Template>
    <div>뉴스레터</div>
    <div style="font-size: 1.5rem; font-weight: bold">
      이메일 인증
      {{
        verifyingDone ?
          verificationResult ? "성공"
          : "실패"
        : "중..."
      }}
    </div>
    <div v-if="verifyingDone">
      <div v-if="verificationResult === 'verified successfully'">
        <p>
          구독 신청을 완료했습니다.<br />제 {{ subscribersCount }}번째 구독자가 되어주셔서
          감사합니다.<br />그럼 다음 글로 곧 찾아뵙겠습니다.
        </p>
      </div>
      <div v-else-if="verificationResult === 'already verified'">
        <p>이미 인증이 완료된 이메일입니다.</p>
      </div>
      <div v-else>
        <p>
          이메일 인증에 실패했습니다.<br />링크가 잘못되었거나 만료되었기 때문일 수 있습니다.<br />문제가
          지속되면<br /><a
            href="mailto:gunny2676@gmail.com"
            target="_blank"
            rel="noopener noreferrer"
            >gunny2676@gmail.com</a
          >으로 연락주세요.
        </p>
      </div>
    </div>
    <div v-else>
      <p>이메일 주소를 인증하는 중입니다...</p>
    </div>
  </Newsletter_Template>
</template>

<style></style>
