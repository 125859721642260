<script setup>
import { defineProps, inject, computed } from "vue"
import { unix2ago } from "@/main"
import { ContentLoader } from "vue-content-loader"

const { lang } = inject("lang")
const { isDark } = inject("isDark")

const props = defineProps({
  p: Object,
  t: String,
})

const elapsed = computed(() => (props.p ? unix2ago(props.p.created, lang.value) : ""))
</script>

<template>
  <li>
    <figure class="link">
      <router-link v-if="props.p" :to="`/p/${props.p.id}${t ? `?t=${t}` : ''}`">
        <span v-html="props.p.title"></span>
        <span v-if="props.p.updated" style="color: #ec4957; font-weight: 900">˙</span>&nbsp;<span
          class="color-subtitle subscript"
          >{{ elapsed }}</span
        >
      </router-link>
      <ContentLoader
        v-else
        width="300"
        height="24"
        viewBox="0 0 300 24"
        :primaryColor="isDark ? '#AAA' : '#CCC'"
        :secondaryColor="isDark ? '#888' : '#BBB'"
        speed="1"
        style="display: block"
      >
        <rect x="0" y="0" rx="4" ry="4" :width="120 + 160 * Math.random()" height="19" />
      </ContentLoader>
    </figure>
  </li>
</template>
