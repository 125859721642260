<template>
  <Page icon="🍀" title="Timeline">
    <Timeline
      :groups="groups"
      :items="items"
      @setEventIdSelected="setEventIdSelected"
      @setDateSelected="setDateSelected"
    />
    <div v-if="eventSelected">
      <h3>{{ eventSelected.title[lang] }}</h3>
      <LifeEvent :event="eventSelected" />
    </div>
    <div v-if="dateSelected">
      <h3>
        {{
          {
            ko: `${dateObjectToStringForRead(dateSelected)}에 저는`,
            en: `On ${dateObjectToStringForRead(dateSelected)} I`,
          }[lang]
        }}
      </h3>
      <ul>
        <li v-for="event in eventsOnDateSelected" :key="event.id">
          <LifeEvent :event="event" />
        </li>
      </ul>
      <div v-if="eventsOnDateSelected.length === 0">
        <p>
          {{
            {
              ko: "아무것도 하지 않았습니다. 데이터베이스 상으로는요.",
              en: "did nothing, at least according to the database.",
            }[lang]
          }}
        </p>
      </div>
    </div>
  </Page>
</template>

<script setup>
import { computed, onMounted, ref, watch, inject } from "vue"
import axios from "axios"
import Page from "../components/Page.vue"
import Timeline from "../components/Timeline.vue"
import LifeEvent from "../components/LifeEvent.vue"

const { lang } = inject("lang")

watch(lang, () => (document.title = lang.value === "ko" ? "삶" : "Life"))

const events = ref([])

const putDashesInDate = (date) => `${date.slice(0, 4)}-${date.slice(4, 6)}-${date.slice(6, 8)}`

const groups = ref([])
const items = computed(() => {
  return events.value.map((event) => ({
    id: event.id,
    subgroup: event.subgroup,
    content: event.title[lang.value],
    start: putDashesInDate(event.start),
    end: putDashesInDate(event.end),
    style: event.style,
    // type: event.start === event.end ? 'point' : null
  }))
})

const eventIdSelected = ref(null)
const setEventIdSelected = (id) => {
  eventIdSelected.value = id
}
const eventSelected = computed(() => {
  return events.value.find((event) => event.id === eventIdSelected.value)
})

function dateObjectTo8NumString(date) {
  return date.toISOString().split("T")[0].replace(/-/g, "")
}

function dateObjectToStringForRead(date) {
  return date.toLocaleDateString({ ko: "ko-KR", en: "en-US" }[lang.value], {
    year: "numeric",
    month: "short",
    day: "2-digit",
    timeZone: "Asia/Seoul",
  })
}

const dateSelected = ref(null)
const setDateSelected = (date_) => {
  dateSelected.value = date_
}
const eventsOnDateSelected = computed(() => {
  if (!dateSelected.value) return []
  const dateSelectedString = dateObjectTo8NumString(dateSelected.value)
  return events.value.filter(
    (event) =>
      (!event.start || event.start <= dateSelectedString) &&
      (!event.end || dateSelectedString <= event.end),
  )
})

async function fetchEvents() {
  const eventsResponse = await axios.get("/api/life")

  const today = new Date()
  const todayString = `${today.getFullYear()}${today.getMonth() + 1}${today.getDate()}`

  events.value = eventsResponse.data.map((event) => ({
    ...event,
    start: event.start || todayString,
    end: event.end || todayString,
  }))
}

onMounted(() => {
  fetchEvents()
})
</script>
