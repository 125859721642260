<script setup>
document.title = "뉴스레터"

import { ref } from "vue"
import { useRoute } from "vue-router"
import axios from "axios"
import Newsletter_Template from "../components/Newsletter_Template.vue"

const route = useRoute()

const email = route.query.email
const token = route.query.token

const errorMessage = ref(null)

function unsubscribe() {
  if (!email) return

  errorMessage.value = null

  axios
    .delete(`/api/subscribers/${email}?token=${token}`)
    .then(() => {
      window.location.href = `/newsletter/unsubscribed?email=${email}`
    })
    .catch(() => {
      errorMessage.value = "등록된 구독자가 아니거나 인증에 실패했습니다."
    })
}
</script>

<template>
  <Newsletter_Template>
    <div>뉴스레터</div>
    <div class="newsletter-title">구독 해지 확인</div>

    <div class="newsletter-message-container">
      <p>떠나가신다니 아쉬워요.<br />그간 당신에게 글을 보낼 수 있어 행복했어요!</p>
    </div>

    <div class="newsletter-message-container">
      <p>아래를 눌러 해지를 완료해주세요.</p>
      <button class="subscribe-button" @click="unsubscribe">
        구독 해지 확인<br />({{ email }})
      </button>
      <p v-if="errorMessage" class="newsletter-warning">{{ errorMessage }}</p>
    </div>
  </Newsletter_Template>
</template>

<style></style>
