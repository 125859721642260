<script setup>
import { ref, inject } from "vue"
import Page from "../components/Page.vue"
// import Stack from '../components/Stack.vue';
import Portfolio_Links from "../components/Portfolio_Links.vue"

const { lang } = inject("lang")

const text = {
  career: [
    {
      period: "2019.11. - 2022.07.",
      affiliation: { ko: "삼성SDS", en: "Samsung SDS" },
      stack: [],
      position: {
        ko: "연구장학생<br/>(분야: blockchain / OCR / AR / code recognition / endpoint security)",
        en: "research scholarship<br/>(fields: blockchain / OCR / AR / code recognition / endpoint security)",
      },
      description: {
        ko: "연구장학생으로서 사내 멘토의 도움을 받아 블록체인 / 광학 문자 인식 / 증강현실 / 코드 인식 / 엔드포인트 보안 등 여러 주제를 매학기 탐구하고 일부 구현했습니다.",
        en: "",
      },
    },
    {
      period: "2020.06. - 2021.07.",
      affiliation: { ko: "KAIST GNSS Lab", en: "KAIST GNSS Lab" },
      stack: ["matlab"],
      position: {
        ko: "URP 및 학부 개별연구생",
        en: "URP / undergraduate individual researcher",
      },
      description: {
        ko: "KAIST 항공우주공학과 위성항법시스템 연구실에서 『항공기 착륙 지원을 위한 한국형 위성항법시스템의 성능 요구도 도출』을 주제로 URP(Undergraduate Research Program) 프로그램 및 학부 개별연구를 수행했습니다. Stanford GPS 연구실에서 GPS와 Galileo 등 위성항법시스템의 성능을 산정하기 위해 개발한 MATLAB 툴셋(MAAST)를 변형하고 여러 배치 시나리오를 대입하여 한국형 위성항법시스템의 최적 배치를 산정하였습니다. 2021년 7월 열린 한국항공우주학회 제9회 학부생논문경진대회에서 같은 주제로 준우수상을 수상했습니다.",
        en: "Carried out URP(Undergraduate Research Program) and Undergraduate Individual Research on 『Analysis on Target Performance of Korea Positioning System for ARAIM Based Aircraft Landing Systems』 at Global Navigation Satellite System Lab in KAIST. In this research the performances of multiple KPS satellite placement scenarios were assessed by modifying MAAST and putting those scenarios into the tool. I was honored to win the third prize with the proposal in the KSAS 9th Undergraduate Academic Competition in July 2021.",
      },
    },
    {
      period: "2020.10. - 2021.07.",
      affiliation: { ko: "Matrios", en: "Matrios" },
      position: {
        ko: "ML·web engineer",
        en: "ML·web engineer",
      },
      stack: [
        "tensorflow",
        "python",
        "cplusplus",
        "react",
        "swift",
        "objectivec",
        "amazonwebservices",
      ],
      description: {
        ko: "on-device 시선추적 기술을 개발하는 스타트업 Matrios에서 기계학습 및 웹애플리케이션 엔지니어로서 근무했습니다. Tensorflow를 사용한 핵심 시선추적 모델을 튜닝하여 성능을 개선하고 요구되는 다른 언어(C++)로 컨버팅하였습니다. 또 이를 활용한 첫 시중 서비스의 웹/iOS 상 프로토타입을 제작했습니다. React, Swift·Objective-C 및 AWS(Amplify, DynamoDB)를 사용했습니다.",
        en: "Worked as a machine learning and web application engineer at Matrios, a startup developing on-device eye tracking technology. Tuned the core eye tracking model in Tensorflow to improve its performance and converted it to other languages (C++). Also developed a web / iOS prototype of the first commercial service using the model. Used React, Swift/Objective-C, and AWS (Amplify, DynamoDB).",
      },
    },
    {
      period: "2019 · 2020 · 2021",
      affiliation: {
        ko: "KAIST-POSTECH 학생대제전",
        en: "KAIST-POSTECH Rivals Match",
      },
      position: {
        ko: "AI경기 학교 대표선수",
        en: "school representative for the AI games",
      },
      stack: ["tensorflow", "python", "cplusplus"],
      description: {
        ko: "양교가 매년 개최하는 KAIST-POSTECH 학생대제전 a.k.a. 카포전의 AI경기 종목에서 3년 연속 학교 대표 선수로 선발되어 참가했습니다.",
        en: "I participated as a school representative in the AI game of KAIST-POSTECH Rivals Match, a.k.a. KAPO-war for three consecutive years.",
      },
      links: [
        {
          title: "GunheeYi/kapo19ai — Github Repository",
          link: "https://github.com/GunheeYi/kapo19ai",
        },
        {
          title: "GunheeYi/kapo20ai — Github Repository",
          link: "https://github.com/GunheeYi/kapo20ai",
        },
        {
          title: "GunheeYi/kapo21ai — Github Repository",
          link: "https://github.com/GunheeYi/kapo21ai",
        },
      ],
    },
    {
      period: "2021.04. - 2022.08.",
      affiliation: { ko: "시대인재", en: "SDIJ" },
      position: {
        ko: "웹 기획·개발자 및 데이터 분석가",
        en: "web planner / developer and data analyst",
      },
      stack: ["figma", "vuejs", "swift", "python", "mysql", "matlab"],
      description: {
        ko: "단과 및 재수종합반을 운영하는 학원 시대인재에서 웹 기획·개발자 및 데이터분석가로서 근무했습니다. 학원의 웹사이트 개편안과 새로운 모바일 어플리케이션을 기획하고 Figma로 목업을 제작했습니다. 그중 일부를 Vue.js와 Swift를 사용하여 프로토타입으로 발전시켰습니다. 또 자습관리 어플리케이션에서 집계된 자료를 이용하여 사용자 추세를 도출하고 성적 자료와 연계 분석하여 서비스의 사용성을 검증했습니다. Python과 MySQL, MATLAB을 사용했습니다.",
        en: "",
      },
    },
    {
      period: "2022.07. - 2022.08.",
      affiliation: { ko: "KAIST Color Lab", en: "KAIST Color Lab" },
      position: {
        ko: "연구보조원",
        en: "research assistant",
      },
      stack: ["opencv", "python"],
      description: {
        ko: "KAIST 산업디자인학과 색체 연구실에서 연구보조원으로 근무했습니다. 사용자 맥락에 따라 자동 제어되는 조명 시스템을 연구, 개발했습니다. 해당 시스템은 Philips Hue API와, OpenCV · YOLOv7을 활용한 동작 감지 및 물체 인식에 기반합니다.",
        en: "",
      },
    },
    // {
    //   period: '2022.12 - 2023.03',
    //   affiliation: { ko: 'COECT', en: 'COECT' },
    //   position: {
    //     ko: '웹서비스 기획·개발 및 video-to-text 컨텐츠 확보 파이프라인 개발',
    //     en: ''
    //   },
    //   description: {
    //     ko: '',
    //     en: ''
    //   },
    // },
    {
      period: "2022.12. - 2023.12.",
      affiliation: { ko: "창업", en: "" },
      position: {
        ko: "ALLCL, BuyBus 등 (하단 프로젝트 참조)",
        en: "",
      },
      description: {
        ko: "ALLCL, COECT, Laminar, Buybus 등 (하단 프로젝트 참조)",
        en: "",
      },
    },
    {
      period: "2024.01. -",
      affiliation: { ko: "선보엔젤파트너스", en: "Sungo Angel Partners" },
      position: {
        ko: "투자 심사 체계 지능화",
        en: "",
      },
      description: {
        ko: "",
        en: "",
      },
    },
  ],
  awards: [
    {
      period: "2020.09. - 2020.10.",
      title: {
        ko: "한국항공우주연구원 주관 위성정보 활용 Space Hackathon",
        en: "KARI Space Hackathon on Satellite Information Applications",
      },
      rank: { ko: "최우수상 (1인 단체)", en: "Grand Award (individual)" },
    },
    {
      period: "2021.01. - 2021.07.",
      title: {
        ko: "제9회 한국항공우주학회 학부생논문경진대회",
        en: "KSAS 9th Undergraduate Academic Competition",
      },
      rank: { ko: "준우수상 (개인)", en: "Excellence Award (individual)" },
    },
    {
      period: "2022.08.",
      title: {
        ko: "2022 고려대학교 여름 해커톤: 함께하는 항해",
        en: "KU Summer Hackathon 2022: 함께하는 항해",
      },
      rank: {
        ko: "4등상 (20개 팀 중, 5인팀장)",
        en: "4th Place Award (out of 20 teams, head of 5p team)",
      },
    },
    {
      period: "2022.09.",
      title: {
        ko: "KAIST 공과대학 Engineering Innovator Award",
        en: "KAIST CoE Engineering Innovator Award",
      },
      rank: {
        ko: "수상 (2022학년도 봄학기, 개인)",
        en: "Received (2022 Spring, individual)",
      },
    },
    {
      period: "2023.11.",
      title: {
        ko: "KAIST 기계항공공학부 항공우주공학과 학사우수상",
        en: "KAIST AE Undergraduate Excellence Award",
      },
      rank: { ko: "수상 (개인)", en: "Received (individual)" },
    },
  ],
  projects: [
    {
      period: "2020.09. - 2020.10.",
      title: {
        ko: "한국항공우주연구원 주관 위성정보 활용 Space Hackathon",
        en: "한국항공우주연구원 주관 위성정보 활용 Space Hackathon",
      },
      stack: [],
      description: {
        ko: "<p>가상의 위성 기반 서비스 부문에서 『위성 기반 전지구적 송전망』을 아이디어로 제안하여 최우수상을 수상했습니다.</p>",
        en: "<p>가상의 위성 기반 서비스 부문에서 『위성 기반 전지구적 송전망』을 아이디어로 제안하여 최우수상을 수상했습니다.</p>",
      },
      links: [],
    },
    {
      period: "2021.03.",
      title: { ko: "Nupjuk!", en: "Nupjuk!" },
      stack: ["html5", "css3", "javascript"],
      description: {
        ko: "<p>KAIST의 학습관리시스템 KLMS의 사용을 보조하는 Chrome 확장프로그램을 개발했습니다. JavaScript와 CSS로 쓰여, 편리한 단축키와 UI 재구성 등의 기능을 포함합니다. 2022년 6월 기준 1,700여 명의 학부·대학원생이 사용합니다.</p>",
        en: "<p>KAIST의 학습관리시스템 KLMS의 사용을 보조하는 Chrome 확장프로그램을 개발했습니다. JavaScript와 CSS로 쓰여, 편리한 단축키와 UI 재구성 등의 기능을 포함합니다. 2022년 6월 기준 1,700여 명의 학부·대학원생이 사용합니다.</p>",
      },
      links: [
        {
          title: "Nupjuk! - Chrome Web Store",
          link: "https://chrome.google.com/webstore/detail/nupjuk/jholkhoploepkmjddgnnhlbmokmjcoim",
        },
        { title: "Nupjuk! — The Documentation", link: "/p/40" },
      ],
    },
    {
      period: "2021.09. - 2021.12.",
      title: { ko: "PintOS", en: "PintOS" },
      stack: ["c"],
      description: {
        ko: "<p>CS330(운영체제 및 실험)을 수강해 사용자 프로그램을 실행하고 가상 메모리 및 파일 시스템을 운용할 수 있는 PintOS을 구현했습니다.</p>",
        en: "<p>CS330(운영체제 및 실험)을 수강해 사용자 프로그램을 실행하고 가상 메모리 및 파일 시스템을 운용할 수 있는 PintOS을 구현했습니다.</p>",
      },
      links: [
        {
          title: "GunheeYi/cs330 — Github Repository",
          link: "https://github.com/GunheeYi/cs330",
        },
      ],
    },
    {
      period: "2021.11. - ",
      title: { ko: "개인 서버 (gunh.ee)", en: "Personal Server (gunh.ee)" },
      stack: [
        "vuejs",
        "express",
        "nodejs",
        "nginx",
        "docker",
        "ubuntu",
        "amazonwebservices",
        // 'html5', 'css3', 'javascript', 'raspberrypi', 'python', 'flask', 'mysql', 'php', 'apache'
      ],
      description: {
        ko: "<p>지금 보고 계신 포트폴리오가 위치한 서버를 구축했고, 현재에도 발전시켜나가고 있습니다. 이는 제 삶 속의 사건들과, 그의 원인과 결과가 되는 생각들을 기록하고 여러분과 공유하는 공간이 됩니다. 이 중 조직화가 가능한 기록들은 정리하여 외부와 상호작용할 수 있는 API로도 제공하고 있습니다.</p>",
        en: "<p>지금 보고 계신 포트폴리오가 위치한 서버를 구축했고, 현재에도 발전시켜나가고 있습니다. 이는 제 삶 속의 사건들과, 그의 원인과 결과가 되는 생각들을 기록하고 여러분과 공유하는 공간이 됩니다. 이 중 조직화가 가능한 기록들은 정리하여 외부와 상호작용할 수 있는 API로도 제공하고 있습니다.</p>",
      },
      links: [],
    },
    {
      period: "2022.03. - 2022.06.",
      title: { ko: "KENSv3", en: "KENSv3" },
      stack: ["cplusplus"],
      description: {
        ko: "<p>CS341(전산망개론)을 수강해 KAIST Educational Network System 상에서 TCP와 라우팅 프로토콜을 구현했습니다.</p>",
        en: "<p>CS341(전산망개론)을 수강해 KAIST Educational Network System 상에서 TCP와 라우팅 프로토콜을 구현했습니다.</p>",
      },
      links: [
        {
          title: "GunheeYi/cs341 — Github Repository",
          link: "https://github.com/GunheeYi/cs341",
        },
      ],
    },
    {
      period: "2022.12. - 2023.06.",
      title: { ko: "ALLCL (올클)", en: "ALLCL (올클)" },
      stack: ["react", "express", "nodejs", "ubuntu", "amazonwebservices", "mongodb"],
      description: {
        ko: "<p>사용자가 클라이밍(볼더링) 문제 풀이 성공 여부를 기록하면, 집계된 내역에 따라 문제별 난이도를 자동 추정하여 제공하는 cross-platform 앱을 개발했습니다.</p>",
        en: "<p>사용자가 클라이밍(볼더링) 문제 풀이 성공 여부를 기록하면, 집계된 내역에 따라 문제별 난이도를 자동 추정하여 제공하는 cross-platform 앱을 개발했습니다.</p>",
      },
      links: [],
    },
    {
      period: "2023.08. -",
      title: { ko: "BuyBus", en: "BuyBus" },
      stack: ["react", "express", "nodejs", "ubuntu", "amazonwebservices", "mongodb"],
      description: {
        ko: "<p>외국인을 위한 국내 고속·시외버스 승차권 예매 대행 서비스를 개발, 서비스하고 있습니다.</p>",
        en: "<p>외국인을 위한 국내 고속·시외버스 승차권 예매 대행 서비스를 개발, 서비스하고 있습니다.</p>",
      },
      links: [
        { title: "BuyBus — Web", link: "https://buybus.kr" },
        { title: "BuyBus — Architecture", link: "/p/73" },
      ],
    },
  ],
}
const exportMode = ref(false)
const now = new Date()
</script>

<template>
  <Page
    :supertitle="
      {
        ko: '이건희 —',
        en: 'Gunhee Aaron Yi —',
      }[lang]
    "
    :title="
      {
        ko: '포트폴리오',
        en: 'Portfolio',
      }[lang]
    "
  >
    <img
      src="https://images.gunh.ee/profile-nz.jpg"
      alt="profile image of Gunhee Yi"
      style="width: 18em; margin-top: 1em"
    />

    <div style="display: flex; gap: 0 1em; flex-wrap: wrap; margin: 0.5em 0">
      <div style="">
        <b>Email</b>
        <div class="link link-inline">
          <a href="mailto:gunny2676@gmail.com" target="_blank"> gunny2676@gmail.com </a>
        </div>
      </div>
      <div>
        <b>GitHub</b>
        <div class="link link-inline">
          <a href="https://github.com/GunheeYi" target="_blank"> GunheeYi </a>
        </div>
      </div>
      <div>
        <b>LinkedIn</b>
        <div class="link link-inline">
          <a href="https://www.linkedin.com/in/gunheeyi/" target="_blank"> gunheeyi </a>
        </div>
      </div>
    </div>

    <div v-if="exportMode && now">
      <p>
        * 본 포트폴리오는
        <span>{{ now.getFullYear() }}년 {{ now.getMonth() + 1 }}월 {{ now.getDate() }}</span
        >일에 내보내어졌습니다. 최신 버전을 보시려면 <a href="https://gunh.ee/portfolio">여기</a>를
        방문해주세요.
      </p>
    </div>

    <hr />

    <div class="column-list">
      <div class="column" style="width: 15%">
        <h2>{{ { ko: "학력", en: "Education" }[lang] }}</h2>
      </div>
      <div class="column" style="width: 85%; line-height: 2">
        <div class="pc-only" style="height: 1.5em" />
        <div class="column-list">
          <div class="column" style="width: 20%">2018.02. - 2023.02.</div>
          <div class="column" style="width: 20%">
            <router-link :to="`/ac/kaist`"
              ><b>{{ { ko: "한국과학기술원", en: "KAIST" }[lang] }}</b></router-link
            >
          </div>
          <div
            class="column"
            style="width: 60%"
            v-html="
              {
                ko: '학사 우수 졸업 (항공우주공학 및 전산학 복수전공)<br/>GPA  3.82 / 4.3  (전공 AE 3.92, CS 3.85)',
                en: 'B.S. Cum Laude in aerospace engineering & computer science<br/>GPA  3.82 / 4.3  (major AE 3.92, CS 3.85)',
              }[lang]
            "
          ></div>
        </div>
        <!-- <div class="column-list">
        <div class="column" style="width: 20%;">
          2020.01 - 2018.03
        </div>
        <div class="column" style="width: 20%;">
          <b>ISAE-SUPAERO</b>
        </div>
        <div class="column" style="width: 60%;">
          교환학생
        </div>
      </div> -->
        <!-- <div class="column-list">
        <div class="column" style="width: 20%;">
          2015.02 - 2018.01
        </div>
        <div class="column" style="width: 20%;">
          <b>하나고등학교</b>
        </div>
        <div class="column" style="width: 60%;">
          졸업
        </div>
      </div> -->
      </div>
    </div>

    <div class="column-list">
      <div class="column" style="width: 15%">
        <h2>{{ { ko: "경력 요약", en: "Career Summary" }[lang] }}</h2>
      </div>
      <div class="column" style="width: 85%; line-height: 2">
        <div class="pc-only" style="height: 1.5em" />
        <div v-for="c in text.career" v-bind:key="c.affiliation.ko" class="column-list">
          <div class="column" style="width: 20%">
            {{ c.period }}
          </div>
          <div class="column" style="width: 20%">
            <b>{{ c.affiliation[lang] }}</b>
          </div>
          <div class="column" style="width: 60%">
            {{ c.position[lang] }}
          </div>
        </div>
      </div>
    </div>

    <div class="column-list">
      <div class="column" style="width: 15%">
        <h2>{{ { ko: "수상 내역", en: "Awards and Honors" }[lang] }}</h2>
      </div>
      <div class="column" style="width: 85%; line-height: 2">
        <div class="pc-only" style="height: 1.5em"></div>
        <div v-for="a in text.awards" v-bind:key="a.title.ko" class="column-list">
          <div class="column" style="width: 20%">
            {{ a.period }}
          </div>
          <div class="column" style="width: 45%">
            <b>{{ a.title[lang] }}</b>
          </div>
          <div class="column" style="width: 35%">
            {{ a.rank[lang] }}
          </div>
        </div>
        <div style="margin-top: 1em">
          <div style="margin-top: 0.5em; display: flex; flex-direction: row; gap: 0.5em">
            <img src="https://images.gunh.ee/portfolio/award-20201007.jpg" style="height: 200px" />
            <img src="https://images.gunh.ee/portfolio/award-20210712.jpg" style="height: 200px" />
            <img src="https://images.gunh.ee/portfolio/award-20220617.jpg" style="height: 200px" />
            <img src="https://images.gunh.ee/portfolio/award-20230226.jpg" style="height: 200px" />
            <img src="https://images.gunh.ee/portfolio/award-20231124.jpg" style="height: 200px" />
          </div>
          <div style="margin-top: 0.5em; display: flex; flex-direction: row; gap: 0.5em">
            <img
              src="https://images.gunh.ee/portfolio/award-20201007-1.jpg"
              style="height: 200px"
            />
            <img src="https://images.gunh.ee/portfolio/award-20220821.jpg" style="height: 200px" />
            <img
              src="https://images.gunh.ee/portfolio/award-20231124-1.jpg"
              style="height: 200px"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="column-list">
      <div class="column" style="width: 15%">
        <h2>{{ { ko: "언어 능력", en: "Language Skills" }[lang] }}</h2>
      </div>
      <div class="column" style="width: 85%; line-height: 2">
        <div class="pc-only" style="height: 1.5em" />
        <div class="column-list">
          <div class="column" style="width: 20%">
            <b>English</b>
          </div>
          <div class="column" style="width: 80%">Professional Working Proficiency (TOEIC 990)</div>
        </div>
        <div class="column-list">
          <div class="column" style="width: 20%">
            <b>Korean</b>
          </div>
          <div class="column" style="width: 80%">Native</div>
        </div>
      </div>
    </div>

    <!-- <hr>
  
  <div class="column-list">
    <div class="column" style="width: 15%;">
      <h2>Skill Set</h2>
    </div>
    <div class="column" style="width: 85%;">
      <div class="pc-only" style="height: 1.5em;"/>
      <div class="column-list">
        <div class="column" style="width: 20%; line-height: 2;">
          <b>언어</b>
        </div>
        <div class="column" style="width: 80%; line-height: 2;">
          C(3) C++(3) Python3(4) JavaScript(4) HTML5(4) CSS3(3) SCSS(2) Markdown(3) PHP(2) Swift(2) Java(2) Scala(1)
        </div>
      </div>
      <div class="column-list">
        <div class="column" style="width: 20%; line-height: 2;">
          <b>운영체제</b>
        </div>
        <div class="column" style="width: 80%; line-height: 2;">
          macOS(4) Linux(3) Windows(4)
        </div>
      </div>
      <div class="column-list">
        <div class="column" style="width: 20%; line-height: 2;">
          <b>프레임워크 / 라이브러리</b>
        </div>
        <div class="column" style="width: 80%; line-height: 2;">
          Tensorflow(3) Torch(3) OpenCV(3) Vue.js(3) React(2) Flask(2)
        </div>
      </div>
      <div class="column-list">
        <div class="column" style="width: 20%; line-height: 2;">
          <b>데이터베이스</b>
        </div>
        <div class="column" style="width: 80%; line-height: 2;">
          MySQL(3) DynamoDB(3)
        </div>
      </div>
      <div class="column-list">
        <div class="column" style="width: 20%; line-height: 2;">
          <b>개발자도구</b>
        </div>
        <div class="column" style="width: 80%; line-height: 2;">
          Visual Studio Code(4) XCode(2) Android Studio(2) Jupyter Notebook(3) Google Colab(3)
        </div>
      </div>
      <div class="column-list">
        <div class="column" style="width: 20%; line-height: 2;">
          <b>협업도구</b>
        </div>
        <div class="column" style="width: 80%; line-height: 2;">
          Git(4) Notion(3) Slack(2)
        </div>
      </div>
      <div class="column-list">
        <div class="column" style="width: 20%; line-height: 2;">
          <b>기타</b>
        </div>
        <div class="column" style="width: 80%; line-height: 2;">
          MATLAB(4) Adobe Illustrator(3) Photoshop(3) Premiere Pro(2) Figma(4) LaTeX(3) Apache2(2)
        </div>
      </div>
      <p style="margin-top: 1rem;"><b>Skill Level</b></p>
      <div class="column-list">
        <div class="column column-4">
          <b>1 - Basic</b>
        </div>
        <div class="column column-4">
          <b>2 - Demonstrating</b>
        </div>
        <div class="column column-4">
          <b>3 - Proficient</b>
        </div>
        <div class="column column-4">
          <b>4 - Expert</b>
        </div>
      </div>
    </div>
  </div> -->

    <hr />

    <h2>{{ { ko: "경력 상세", en: "Career Details" }[lang] }}</h2>

    <div v-for="c in text.career" v-bind:key="c.affiliation.ko" class="column-list">
      <div class="column" style="width: 20%; margin-top: 1.2rem">
        {{ c.period }}
      </div>
      <div class="column" style="width: 80%">
        <h3>{{ c.affiliation[lang] }}</h3>
        <!-- <Stack :stack="c.stack"/> -->
        <p>{{ c.description[lang] }}</p>
        <Portfolio_Links :links="c.links" />
      </div>
    </div>

    <hr />

    <h2>{{ { ko: "프로젝트", en: "Projects" }[lang] }}</h2>

    <div v-for="(p, pi) in text.projects" v-bind:key="pi" class="column-list">
      <div class="column" style="width: 20%; margin-top: 1.2rem" v-html="p.period"></div>
      <div class="column" style="width: 80%">
        <h3 v-html="p.title[lang]"></h3>
        <!-- <Stack :stack="p.stack"/> -->
        <div v-html="p.description[lang]"></div>
        <Portfolio_Links :links="p.links" />
      </div>
    </div>

    <br />
    <div>
      <input type="checkbox" v-model="exportMode" />&nbsp;<span style="opacity: 0.5"
        >enable export mode</span
      >
    </div>
  </Page>
</template>

<style scoped>
.page-body .column-list {
  margin-bottom: 0.2em;
}

hr {
  margin-top: 1.5em;
}
</style>
