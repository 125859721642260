<script setup>
import { inject } from "vue"
import Page from "../components/Page.vue"

const { lang } = inject("lang")
</script>

<template>
  <Page title="404 Not Found 🤦🏻‍♂️">
    <p>
      {{ { ko: "페이지를 찾을 수 없습니다.", en: "Page not found." }[lang] }}
    </p>
  </Page>
</template>
