<template>
  <div v-if="event">
    <p>
      {{ (lang === "ko" ? "" : "I ") + props.event.description[lang] }}
      <span class="color-subtitle subscript"
        >({{ props.event.start }} ~ {{ props.event.end }})</span
      >
    </p>
  </div>
</template>

<script setup>
import { defineProps, inject } from "vue"

const { lang } = inject("lang")

const props = defineProps(["event"])
</script>
